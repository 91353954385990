import React from "react";

import useWindowSize from "../../hooks/useWindowSize";

import HomepageMobile from "./HomepageMobile";
import HomepageDesktop from "./HomepageDesktop";

const Homepage = () => {
  const size = useWindowSize();

  if (size.width < 1080) {
    return <HomepageMobile />;
  } else {
    return <HomepageDesktop />;
  }
};

export default Homepage;
