import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import CtaButton from "../../CtaButton";

import poppyWhite from "../../../assets/images/poppy-white.svg";

import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";

const StartGameOverlay = ({ setShowStart, handleStartEndButtonClick }) => {
  const gaEventTracker = useAnalyticsEventTracker("howToPlay");
  const overlayRef = useRef(null);
  const [overlayWidth, setOverlayWidth] = useState(0);

  useEffect(() => {
    gaEventTracker("view", "How to Play");
  }, [gaEventTracker]);

  useEffect(() => {
    setOverlayWidth(overlayRef.current?.offsetWidth);
  }, []);

  return (
    <StyledStartGameOverlay $width={overlayWidth} ref={overlayRef}>
      <div className="start-overlay__top-left"></div>
      <div className="start-overlay__center">
        <div className="start-overlay__heading">
          <img
            className="start-overlay__heading-logo"
            src={poppyWhite}
            alt="Popeyes Poppy the chicken logo"
          />
          <h1 className="start-overlay__heading-text">
            <span>How to</span> <span>Play</span>
          </h1>
        </div>
        <p className="start-overlay__copy">
          When you see Poppy, make sure to tap and hit the screen! Just
          remember, Poppy comes in all shapes and forms.
        </p>
        <CtaButton
          ctaText="Play Game"
          ctaColor="orangeLight"
          onClick={handleStartEndButtonClick}
        />
      </div>
    </StyledStartGameOverlay>
  );
};

const StyledStartGameOverlay = styled.section`
  background-color: rgba(0, 85, 96, 0.9);
  color: ${({ theme }) => theme.colors.offWhite};
  display: grid;
  grid-area: 1 / 1;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: auto;
  justify-items: start;
  padding-bottom: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 2rem;
  position: relative;
  width: 100%;

  @media (min-width: 960px) {
    padding-bottom: 3rem;
    padding-top: 9rem;
  }

  @media (min-width: 1200px) {
    padding-bottom: 2.5rem;
    padding-top: 12rem;
  }

  @media (min-width: 1400px) {
    padding-top: 11rem;
  }

  .start-overlay {
    &__heading {
      font-size: 1.25rem;

      @media (min-width: 1200px) {
        font-size: 2rem;
      }
    }

    &__copy {
      font-size: 0.75rem;
      line-height: 1.2;

      @media (min-width: 1200px) {
        font-size: 1rem;
      }
    }

    &__center {
      align-items: center;
      bottom: 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: 100%;
      justify-content: center;
      left: 0;
      margin: auto;
      max-width: 18rem;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      transform: translateY(-1.25rem);
      width: 100%;

      @media (min-width: 960px) {
        max-width: 27.5rem;
      }

      @media (min-width: 1200px) {
        gap: 1.5rem;
        transform: translateY(-2.5rem);
        max-width: 35rem;
      }

      .start-overlay__heading {
        display: flex;
        gap: 1rem;

        @media (min-width: 960px) {
          gap: 1.5rem;
        }

        img {
          width: 4rem;

          @media (min-width: 960px) {
            width: 6rem;
          }

          @media (min-width: 1200px) {
            width: 7.5rem;
          }
        }

        &-text {
          display: flex;
          flex-direction: column;
          font-size: 2rem;
          line-height: 1;

          @media (min-width: 960px) {
            font-size: 3rem;
          }

          @media (min-width: 1200px) {
            font-size: 4rem;
          }
        }
      }

      .start-overlay__copy {
        @media (min-width: 1200px) {
          font-size: 1rem;
        }

        @media (min-width: 1200px) {
          font-size: 1rem;
        }
      }
    }
  }
`;

export default StartGameOverlay;
