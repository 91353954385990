const handleExplodeParticles = function (e, container = false) {
  const shapes = [
    '<svg viewBox="0 0 225.7 216.95" xmlns="http://www.w3.org/2000/svg"><g fill="#eb7300"><path d="m18 77.54 16.65 20.1s8.9-13.08 19.88-4.3c10.97 8.78 3.3 26.8 2.29 37.18-1.29 13.21 1.97 23.89 7.29 32.37 6.76 10.8 22.46 19.78 39.32 22.95 1.29.24 2.53.42 3.75.57l1.75 18.58h12.7l1.71-18.16c29.14-2.34 70.37-30.86 52.03-78.86-8.44-22.09-26.33-55.07-26.33-55.07l.21-.06c7.08-1.8 8.45-10.99.18-15.92l5.91-5.47-8.65-3.54 3.23-11.82-18.56 7.36s.05-.04.05-.04-4.7-9.81-11.85-9.89c-6.03-.07-8.87 4.29-8.87 4.29s-1.6-4.48-5.73-5.71c-4.13-1.22-10.85 1.18-11.7 8.66-.96 8.39 5.82 12.71 8.05 13.43 2.65.85 6.31 2.55 13.98-.68-.01 7.7-.04 25.55-.06 34.52-.02 11.63 1.03 30.49-3.96 40.72s-10.68 14.49-18.22 13.6c-8.42-.99-11.3-8.87-8.37-23.5 2.92-14.63 5.39-29.18-6.87-40.02s-34.33-12.03-59.79 18.7m114.97-46.96c1.9-.97 4.31-.03 5.4 2.1s.43 4.64-1.46 5.61c-1.9.97-4.31.03-5.4-2.1s-.43-4.64 1.46-5.61"/><path d="m179.37 166.97c0 1.69-1.33 3.03-3.06 3.03s-3.08-1.33-3.08-3.03 1.37-2.99 3.08-2.99 3.06 1.33 3.06 2.99zm-5.38 0c0 1.33.99 2.39 2.33 2.39s2.28-1.06 2.28-2.37-.97-2.41-2.3-2.41-2.31 1.08-2.31 2.39zm1.84 1.57h-.69v-2.99c.27-.05.66-.09 1.15-.09.56 0 .82.09 1.04.22.16.13.29.36.29.66 0 .33-.26.58-.62.69v.04c.29.11.46.33.55.73.09.46.15.64.22.75h-.75c-.09-.11-.15-.38-.24-.73-.05-.33-.24-.47-.62-.47h-.33v1.2zm.02-1.69h.33c.38 0 .69-.13.69-.44 0-.27-.2-.46-.64-.46-.18 0-.31.02-.38.04z"/></g></svg>',
  ];

  // Create a new <div> element
  const particleContainer = document.createElement("div");

  // Apply styles to position the <div> relative to the screen
  particleContainer.style.position = "absolute"; // or 'absolute' depending on your requirements
  particleContainer.style.top = "50px"; // adjust the top position as needed
  particleContainer.style.left = "50px"; // adjust the left position as needed
  particleContainer.style.width = "100px"; // adjust the width as needed
  particleContainer.style.height = "100px"; // adjust the height as needed
  // particleContainer.style.overflow = 'hidden'; // in case the content is larger than the container

  // Append the <div> element to the document body or any other container
  if (container) {
    container.appendChild(particleContainer);
  } else {
    document.body.appendChild(particleContainer);
  }

  const btn = particleContainer;
  let group = [];
  const num = 24;

  function getRandomRotation(minDegrees, maxDegrees) {
    const random = Math.random();
    const range = maxDegrees - minDegrees;
    const rotation = random * range + minDegrees;

    return rotation;
  }

  const minRotation = -180;
  const maxRotation = 180;

  for (let i = 0; i < num; i++) {
    const getShape = Math.floor(Math.random() * shapes.length);
    const scale = Math.floor(Math.random() * (8 - 4 + 1)) + 4;
    const x = Math.floor(Math.random() * 1000);
    const y = Math.floor(Math.random() * 1000);
    const sec = Math.floor(Math.random() * 1700) + 1000;
    const shape = document.createElement("div");
    shape.innerHTML = shapes[getShape];
    shape.className = "shape";
    shape.style.position = "absolute";
    shape.style.top = e.pageY - btn.offsetTop * 2.5 + "px";
    shape.style.left = e.pageX - btn.offsetLeft * 2 + "px";
    shape.style.transform = `scale(0.${scale}) rotate(${getRandomRotation(
      minRotation,
      maxRotation
    )}deg)`;
    shape.style.transition = sec + "ms";
    btn.appendChild(shape);

    group.push({ shape: shape, x: x, y: y });
  }

  for (let a = 0; a < group.length; a++) {
    const shape = group[a].shape;
    const x = group[a].x,
      y = group[a].y;
    shape.style.left = x + 50 + "px";
    shape.style.top = y + 15 + "px";
    shape.style.opacity = 0;
  }

  setTimeout(function () {
    for (let b = 0; b < group.length; b++) {
      const shape = group[b].shape;
      shape.remove();
    }
    group = [];
    btn.remove();
  }, 2000);
};

export default handleExplodeParticles;
