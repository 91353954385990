import React, { useEffect } from "react";
import styled from "styled-components";

import useDeviceType from "../../hooks/useDeviceType";

import CtaButton from "../CtaButton";

import gameOverHeader from "../../assets/images/game-over-page-header.svg";
import popeyesLogo from "../../assets/images/popeyes-logo.svg";
import appIcon from "../../assets/images/app-icon.svg";

import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const ComingSoonPage = () => {
  const gaEventTracker = useAnalyticsEventTracker("promotionOver");
  const deviceType = useDeviceType();
  const appLink =
    deviceType === "Android"
      ? "https://play.google.com/store/apps/details?id=com.emn8.mobilem8.nativeapp.popeyes&hl=en_US&gl=US&pli=1"
      : "https://apps.apple.com/us/app/popeyes/id1386566985";

  useEffect(() => {
    gaEventTracker("view", "promotion over");
  }, [gaEventTracker]);

  return (
    <StyledComingSoonPage>
      <div className="coming-soon__content-wrapper">
        <div>
          <img
            className="coming-soon__header-img"
            src={gameOverHeader}
            alt="Poppy has been found."
          />
        </div>
        <div className="coming-soon__copy-wrapper">
          <h1>Sorry, Poppy has been found.</h1>
        </div>
        <div className="coming-soon__app-wrapper">
          <img src={appIcon} alt="Popeye's mobile app" />
          <CtaButton
            ctaColor="darkGreen"
            ctaText="Download the app"
            ctaUrl={appLink}
            onClick={() => gaEventTracker("click", "download the app")}
          />
          <p>to find more deals</p>
        </div>
      </div>
      <div>
        <img className="home__logo" src={popeyesLogo} alt="Popeyes logo" />
      </div>
    </StyledComingSoonPage>
  );
};

const StyledComingSoonPage = styled.section`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.orangeLight};
  color: ${({ theme }) => theme.colors.offWhite};
  display: grid;
  gap: 3rem;
  grid-template-rows: auto max-content;
  height: 100vh;
  justify-items: center;
  padding: 1.5rem;

  @media (min-width: 50rem) {
    padding: 3rem;
  }

  .coming-soon {
    &__content-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      justify-content: center;
      padding-top: 1.5rem;
    }

    &__header-img {
      margin-left: auto;
      margin-right: auto;
      max-width: 10rem;
      width: 100%;

      @media (orientation: landscape) and (max-width: 50rem) {
        width: 40%;
      }
    }

    &__copy-wrapper {
      line-height: 1.25;
      text-align: center;

      h1 {
        font-size: 2rem;
        font-size: clamp(2rem, 1.5rem + 2.5vw, 4rem);
      }
    }

    &__app-wrapper {
      align-items: center;
      color: ${({ theme }) => theme.colors.offWhite};
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      justify-content: center;
      line-height: 1;

      @media (min-width: 50rem) {
        padding-top: 3rem;
      }

      img {
        width: 45%;
      }
    }
  }
`;

export default ComingSoonPage;
