import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import homeIcon from "../../assets/images/home-icon.svg";

import { content } from "../../content";

import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const FAQPage = () => {
  const gaEventTracker = useAnalyticsEventTracker("faq");

  const handleBackToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Edge, and Firefox
  };

  useEffect(() => {
    gaEventTracker("view", "faq");
  }, [gaEventTracker]);

  return (
    <StyledFAQPage>
      <div className="faq-page__wrapper">
        <Link
          className="faq-page__home-link"
          to="/"
          onClick={() => gaEventTracker("click", "home")}
        >
          <img src={homeIcon} alt="Poppy the chicken in a house" />
        </Link>
        <div className="faq-page__left-col">
          <div className="faq-page__heading-wrapper">
            <h1>{content.faqPage.heading}</h1>
            <p>{content.faqPage.copy}</p>
          </div>
        </div>
        <div className="faq-page__right-col">
          <ul className="faq-page__faq-list">
            {content.faqPage.faqs.map((faq) => (
              <li className="faq-page__faq-item" key={faq.question}>
                <h2>{faq.question}</h2>
                <p>{faq.answer}</p>
              </li>
            ))}
          </ul>
          <button
            className="back-to-top-btn"
            type="button"
            onClick={handleBackToTop}
          >
            Back to top
          </button>
        </div>
      </div>
    </StyledFAQPage>
  );
};

const StyledFAQPage = styled.section`
  .faq-page {
    &__home-link {
      left: 0.75rem;
      position: absolute;
      top: 0.75rem;
      width: 2rem;
      z-index: 2;

      @media (min-width: 1080px) {
        width: auto;
      }
    }

    &__wrapper {
      background-color: ${({ theme }) => theme.colors.orangeLight};
      color: ${({ theme }) => theme.colors.offWhite};
      display: flex;
      flex-direction: column;

      @media (min-width: 1080px) {
        flex-direction: row;
        height: 100vh;
      }
    }

    &__left-col {
      align-items: center;
      background-color: ${({ theme }) => theme.colors.orangeDark};
      display: flex;
      justify-content: center;
      padding: 3rem;
      text-align: center;

      @media (min-width: 1080px) {
        flex-basis: 60%;
      }
    }

    &__right-col {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 3rem;
      -ms-overflow-style: none;
      scrollbar-width: none;

      @media (min-width: 1080px) {
        flex-basis: 40%;
      }

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        display: none;
      }
    }

    &__heading-wrapper {
      h1 {
        font-family: "ChickenSans-Bold", sans-serif;
        font-size: 2.25rem;
        line-height: 1;
        margin-bottom: 1.5rem;
        text-align: center;

        @media (min-width: 1080px) {
          font-size: 4rem;
        }
      }

      p {
        font-family: "ChickenSans-Bold", sans-serif;
        font-size: 1.25rem;
        line-height: 1.2;
        margin: 0 auto;
        max-width: 15rem;
        text-align: center;

        @media (min-width: 1080px) {
          font-size: 1.75rem;
          line-height: 1;
          max-width: 40rem;
        }
      }
    }

    &__faq-list {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      list-style: none;
      margin: 0;
      padding: 0;
      overflow-y: scroll;

      @media (min-width: 1080px) {
        font-size: 125%;
      }

      h2 {
        line-height: 1;
        margin-bottom: 0.5rem;

        @media (min-width: 1080px) {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .back-to-top-btn {
    color: ${({ theme }) => theme.colors.offWhite};
    cursor: pointer;
    font-size: 0.875rem;
    margin-top: 4.5rem;

    @media (min-width: 1080px) {
      display: none;
    }
  }
`;

export default FAQPage;
