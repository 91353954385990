import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import useDeviceType from "../../../hooks/useDeviceType";

import CtaButton from "../../CtaButton";
import SocialLinks from "../../SocialLinks/SocialLinks";

import homeIcon from "../../../assets/images/home-icon.svg";
import closeIcon from "../../../assets/images/close-icon.svg";
import myPoppyPrizesRibbon from "../../../assets/images/enter-now-ribbon-dark.svg";
import appIcon from "../../../assets/images/app-icon.svg";
import prizesBand from "../../../assets/images/prizes-band.png";

import { content } from "../../../content";

import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";

const PrizesOverlay = ({ handleClick }) => {
  const gaEventTracker = useAnalyticsEventTracker("myPrizes");

  const isMac = window.navigator.userAgent.indexOf("Mac") !== -1;
  const deviceType = useDeviceType();
  let appLink = "https://apps.apple.com/us/app/popeyes/id1386566985";
  if (deviceType === "Android") {
    appLink =
      "https://play.google.com/store/apps/details?id=com.emn8.mobilem8.nativeapp.popeyes&hl=en_US&gl=US&pli=1";
  } else if (deviceType === "iOS") {
    appLink = "https://apps.apple.com/us/app/popeyes/id1386566985";
  } else if (isMac) {
    appLink = "https://apps.apple.com/us/app/popeyes/id1386566985";
  } else {
    appLink =
      "https://play.google.com/store/apps/details?id=com.emn8.mobilem8.nativeapp.popeyes&hl=en_US&gl=US&pli=1";
  }

  useEffect(() => {
    gaEventTracker("view", "my prizes");
  }, [gaEventTracker]);

  return (
    <StyledPrizesOverlay>
      <button
        className="prizes-overlay__close-button"
        type="button"
        onClick={handleClick}
      >
        <img src={closeIcon} alt="Close the Poppy Tracker" />
      </button>
      <div className="prizes-overlay__wrapper">
        <Link
          className="prizes-overlay__home-link"
          to="/"
          onClick={() => gaEventTracker("click", "Home")}
        >
          <img src={homeIcon} alt="Poppy the chicken in a house" />
        </Link>
        <div className="prizes-overlay__left-col">
          <h1 className="visuallyhidden">Poppy Prizes</h1>
          <img
            className="prizes-overlay__ribbon"
            src={myPoppyPrizesRibbon}
            alt="Enter Now ribbon"
          />
          <div className="prizes-overlay__app-wrapper">
            <img src={appIcon} alt="Popeye's mobile app" />
            <div className="prizes-overlay__app-cta-wrapper">
              <CtaButton
                className="prizes-overlay__cta-button"
                ctaColor="darkGreen"
                ctaText="Download the app"
                ctaUrl={appLink}
                isLarge
                isExternal
                onClick={() => gaEventTracker("click", "download the app")}
              />
            </div>
          </div>
          <SocialLinks myPrizes />
        </div>

        <div className="prizes-overlay__right-col">
          <div className="prizes-overlay__right-col-wrapper">
            <h1>{content.prizesOverlay.heading}</h1>
            <p>{content.prizesOverlay.copyTop}</p>
            <div className="prizes-overlay__img-wrapper">
              <img
                src={prizesBand}
                alt="A brass band standing on a New Orleans street."
              />
            </div>
            <p>{content.prizesOverlay.copyBottom}</p>
            <CtaButton
              ctaColor="darkGreen"
              ctaText="Register"
              ctaUrl="/registration"
              isLarge
              onClick={() => gaEventTracker("click", "register")}
            />
          </div>
          <p>
            <small>{content.prizesOverlay.legalTop}</small>
          </p>
          <p className="prizes-overlay__legalese">
            <small>{content.prizesOverlay.legalBottom}</small>
          </p>
        </div>
      </div>
    </StyledPrizesOverlay>
  );
};

const StyledPrizesOverlay = styled.section`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.orangeLight};
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  margin: auto;
  padding: 1.5rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 6;

  @media (min-width: 1200px) {
    padding: 2.5rem;
  }

  .prizes-overlay {
    &__cta-button {
      font-size: 0.75rem;

      @media (min-width: 1020px) {
        font-size: 1rem;
      }
    }

    &__close-button {
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 1rem;
      transition: scale 0.2s ease-out;
      width: 2rem;

      @media (min-width: 1200px) {
        width: 3rem;
      }

      @media (min-width: 1500px) {
        right: 2rem;
        top: 2rem;
      }

      &:focus,
      &:hover {
        transform: scale(1.05);
      }
    }

    &__home-link {
      left: 0.75rem;
      max-width: 2.5rem;
      position: absolute;
      top: 0.75rem;
      width: 1.5rem;
      z-index: 2;

      @media (min-width: 1080px) {
        width: auto;
      }
    }

    &__wrapper {
      display: flex;
      gap: 1rem;
      height: 100%;
      max-width: 100rem;
      width: 100%;

      @media (min-width: 1200px) {
        gap: 3rem;
      }
    }

    &__left-col {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      max-width: 31%;

      @media (min-width: 960px) {
        gap: 1.5rem;
      }

      @media (min-width: 1200px) {
        gap: 2rem;
      }

      #social-links {
        max-width: 9rem;

        @media (min-width: 1080px) {
          max-width: 12.5rem;
        }
      }
    }

    &__ribbon {
      width: 50%;

      @media (min-width: 1020px) {
        width: 60%;
      }

      @media (min-width: 1400px) {
        width: 100%;
      }
    }

    &__app-wrapper {
      align-items: center;
      color: ${({ theme }) => theme.colors.offWhite};
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      line-height: 1;

      @media (min-width: 1200px) {
        gap: 1.5rem;
      }

      img {
        width: 40%;

        @media (min-width: 1200px) {
          width: auto;
        }
      }
    }

    &__app-cta-wrapper {
      align-items: center;
      display: flex;
    }

    &__right-col {
      align-items: center;
      background-color: ${({ theme }) => theme.colors.offWhite};
      color: ${({ theme }) => theme.colors.orangeDark};
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      padding: 1rem;
      text-align: center;
      width: 100%;

      @media (min-width: 1200px) {
        padding: 1.5rem;
      }

      @media (min-width: 1360px) {
        justify-content: center;
        overflow-y: hidden;
      }

      @media (min-width: 1500px) {
        padding: 0 3rem;
      }

      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 11px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        border: 2px solid ${({ theme }) => theme.colors.offWhite};
      }

      &-wrapper {
        max-width: 20rem;
        padding-top: 1rem;

        @media (min-width: 880px) {
          max-width: 32rem;
        }

        @media (min-width: 1200px) {
          padding-top: 1.5rem;
        }

        @media (min-width: 1500px) {
          padding-top: 3rem;
        }

        h1 {
          font-size: 1.5rem;
          font-size: clamp(
            1.5rem,
            0.6443298969072165rem + 2.0618556701030926vw,
            2.5rem
          );
          line-height: 1;
          margin-bottom: 1rem;
        }

        p {
          font-size: 1.25rem;
          font-size: clamp(
            1.25rem,
            0.8221649484536082rem + 1.0309278350515463vw,
            1.75rem
          );
          line-height: 1;
          margin-bottom: 1.75rem;
        }

        a {
          margin-bottom: 1.75rem;
        }
      }
    }

    &__img-wrapper {
      align-items: center;
      display: flex;
      margin-bottom: 1.5rem;
      margin-left: auto;
      margin-right: auto;
    }

    &__legalese {
      max-width: 45rem;
    }
  }

  small {
    font-family: "ChickenSans-Regular", sans-serif;
  }

  p:has(small) {
    line-height: 1;
    padding-bottom: 1rem;
  }
`;

export default PrizesOverlay;
