import { useState, useEffect } from "react";

function useOrientation() {
  const isLandscape = () =>
    window.matchMedia("(orientation:landscape)").matches;
  const [orientation, setOrientation] = useState(
    isLandscape() ? "landscape" : "portrait"
  );

  useEffect(() => {
    const onWindowResize = () => {
      clearTimeout(window.resizeLag);
      window.resizeLag = setTimeout(() => {
        delete window.resizeLag;
        setOrientation(isLandscape() ? "landscape" : "portrait");
      }, 200);
    };

    onWindowResize();
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  return orientation;
}

export default useOrientation;
