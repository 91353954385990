import React, { useEffect } from "react";
import styled from "styled-components";

import CtaButton from "../../CtaButton";
import SocialLinks from "../../SocialLinks/SocialLinks";

import sadPoppy from "../../../assets/images/sad-poppy.svg";
import happyPoppy from "../../../assets/images/poppy-white.svg";

import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";

const EndGameOverlay = ({
  handleEndSearchClick,
  handlePrizesClick,
  totalClicked,
}) => {
  const collectedAll = totalClicked === 20;
  const headlineText = collectedAll ? "Whoowee!" : "Nice try!";

  const gaEventTracker = useAnalyticsEventTracker(
    collectedAll ? "endscreen_whoowee" : "endscreen_nicetry"
  );

  useEffect(() => {
    gaEventTracker("view", collectedAll ? "whoowee screen" : "nice try screen");
  }, [gaEventTracker]);

  const bodyText = collectedAll ? (
    <p className="end-game__text">
      Congrats!
      <br />
      You found all the poppys!
    </p>
  ) : (
    <p className="end-game__text">
      You made it through, but didn't find all 20{" "}
      <span className="font-script">Poppys</span>.
    </p>
  );

  const poppyImage = collectedAll ? (
    <img className="end-game__logo" src={happyPoppy} alt="Popeyes Poppy logo" />
  ) : (
    <img
      className="end-game__logo"
      src={sadPoppy}
      alt="Popeyes Poppy logo crying"
    />
  );

  const handleShowPrizes = () => {
    handlePrizesClick();
    gaEventTracker("click", "enter now");
  };

  return (
    <StyledEndGameOverlay $collectedAll={collectedAll}>
      <div className="end-game__header-wrap">
        <div>{poppyImage}</div>
        <h1 className="end-game__headline">{headlineText}</h1>
      </div>
      {bodyText}
      <div className="end-game__button-wrap">
        {!collectedAll && (
          <CtaButton
            className="end-game__button"
            ctaColor="orangeLight"
            ctaText="Keep Looking"
            onClick={handleEndSearchClick}
          />
        )}
        <CtaButton
          className="end-game__button"
          ctaColor="orangeLight"
          ctaText="Enter Now"
          onClick={handleShowPrizes}
        />
      </div>
      <SocialLinks collectedAll={collectedAll} />
    </StyledEndGameOverlay>
  );
};

const StyledEndGameOverlay = styled.section`
  align-items: center;
  background-color: rgba(0, 85, 96, 0.8);
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  left: 0;
  padding: 1rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;

  @media (min-width: 960px) {
    gap: 2rem;
  }

  .end-game {
    &__button {
      font-size: 0.75rem;

      @media (min-width: 760px) {
        font-size: 0.95rem;
      }

      @media (min-width: 960px) {
        font-size: 1.75rem;
      }
    }

    &__header-wrap {
      align-items: center;
      display: flex;
      gap: 1rem;
      justify-content: center;
    }

    &__headline {
      color: ${({ theme }) => theme.colors.offWhite};
      font-size: 2rem;
      line-height: 0.8;
      text-align: center;
      transform: translateY(-0.5rem);

      @media (min-width: 760px) {
        font-size: 2.65rem;
      }

      @media (min-width: 960px) {
        font-size: 2.75rem;
        font-size: clamp(2.75rem, 0.1499999999999999rem + 6.5vw, 6rem);
      }
    }

    &__text {
      color: ${({ theme }) => theme.colors.offWhite};
      font-size: 0.85rem;
      line-height: 1;
      max-width: 35rem;
      text-align: center;
      width: ${({ $collectedAll }) => ($collectedAll ? "90%" : "52.5%")};

      @media (min-width: 760px) {
        font-size: 1.4rem;
      }

      @media (min-width: 960px) {
        font-size: 1.75rem;
        font-size: clamp(1.75rem, 0.75rem + 2.5vw, 3rem);
      }
    }

    &__button-wrap {
      display: flex;
      gap: 1rem;
      padding-top: 1rem;

      @media (min-width: 960px) {
        gap: 2rem;
        padding-top: 1.5rem;
      }
    }

    &__logo {
      height: 4rem;

      @media (min-width: 760px) {
        height: 9rem;
      }

      @media (min-width: 960px) {
        height: 12rem;
      }
    }
  }

  #social-links {
    max-width: 7.5rem;

    @media (min-width: 1080px) {
      max-width: 12rem;
    }
  }
`;

export default EndGameOverlay;
