import React from "react";
import styled from "styled-components";

import topRightArrow from "../../../assets/images/start-overlay-arrow-top-right.svg";

const UpperRightLarge = () => {
  return (
    <StyledUpperRight>
      <img
        src={topRightArrow}
        alt="arrow pointing to the button to click to see your prizes"
      />
      <div className="start-overlay__top-right-copy">
        <p className="start-overlay__copy">
          Play every day for an
          <br />
          additional chance to win.
        </p>
      </div>
    </StyledUpperRight>
  );
};

const StyledUpperRight = styled.div`
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.offWhite};
  display: flex;
  justify-self: end;
  left: -115px;
  max-width: 30rem;
  padding-top: 4rem;
  position: absolute;
  top: 105px;
  text-align: right;

  @media (min-width: 1200px) {
    left: -180px;
    top: 110px;
  }

  img {
    height: 5rem;
    transform: translate(3rem, -80%);

    @media (min-width: 1200px) {
      height: 6rem;
      transform: translate(5rem, -80%);
    }
  }

  .start-overlay__copy {
    font-size: 0.75rem;
    line-height: 1.2;

    @media (min-width: 1200px) {
      font-size: 1rem;
    }
    max-width: 20rem;
  }
`;

export default UpperRightLarge;
