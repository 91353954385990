import React from "react";
import styled from "styled-components";

import bottomLeftArrow from "../../../assets/images/start-overlay-arrow-bottom-left.svg";

const LowerLeftLarge = () => {
  return (
    <StyledLowerLeft>
      <div>
        <p className="start-overlay__copy">
          Play and Pause the video to find
          <br />
          all of the Poppys. They are sneaky.
        </p>
      </div>
      <img
        src={bottomLeftArrow}
        alt="arrow pointing to the pause/play button"
      />
    </StyledLowerLeft>
  );
};

const StyledLowerLeft = styled.div`
  align-self: end;
  color: ${({ theme }) => theme.colors.offWhite};
  left: 0;
  position: absolute;
  top: -48px;
  width: max-content;

  @media (min-width: 1200px) {
    top: -90px;
  }

  img {
    height: 2.5rem;
    transform: translate(3rem, 15%);

    @media (min-width: 960px) {
      height: 3.5rem;
      transform: translate(5rem, 7%);
    }

    @media (min-width: 1200px) {
      height: 5rem;
    }
  }

  .start-overlay__copy {
    font-size: 0.75rem;
    line-height: 1.2;

    @media (min-width: 1200px) {
      font-size: 1rem;
    }
    max-width: 20rem;
  }
`;

export default LowerLeftLarge;
