import React, { useEffect } from "react";
import styled from "styled-components";

import CtaButton from "../CtaButton";

import poppyWhite from "../../assets/images/poppy-white.svg";
import heroVideoStill from "../../assets/images/hero-video-still.jpg";
import homePrizesBand from "../../assets/images/home-prizes-band.jpg";
import popeyesLogo from "../../assets/images/popeyes-logo.svg";
import bgPattern from "../../assets/images/bg-pattern-dark-green.png";

import { content } from "../../content";

import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const handleBackToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Edge, and Firefox
};

const handleFullscreen = (gaEventTracker) => {
  var isInFullScreen =
    (document.fullscreenElement && document.fullscreenElement !== null) ||
    (document.webkitFullscreenElement &&
      document.webkitFullscreenElement !== null) ||
    (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
    (document.msFullscreenElement && document.msFullscreenElement !== null);

  var docElm = document.documentElement;
  if (!isInFullScreen) {
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    } else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen();
    } else if (docElm.webkitRequestFullScreen) {
      docElm.webkitRequestFullScreen();
    } else if (docElm.msRequestFullscreen) {
      docElm.msRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  gaEventTracker("click", "get looking");
};

const HomepageMobile = () => {
  const gaEventTracker = useAnalyticsEventTracker("homepage");

  useEffect(() => {
    gaEventTracker("view", "homepage");
  }, [gaEventTracker]);

  return (
    <StyledHomepageMobile>
      <section
        className="hero"
        style={{
          backgroundImage: `url(${bgPattern})`,
          backgroundPosition: "center",
        }}
      >
        <div className="home__wrapper">
          <div className="home__heading">
            <img
              className="home__heading-logo"
              src={popeyesLogo}
              alt="Popeyes logo"
            />
            <div className="home__heading-poppy-wrapper">
              <img
                className="home__heading-poppy"
                src={poppyWhite}
                alt="Popeyes Poppy the chicken logo"
              />
              <h1 className="home__heading-text">
                <span className="font-script">Poppy</span> is Ready to Play.
                <br />
                Are You?
              </h1>
            </div>
          </div>
          <div className="hero__img-wrapper">
            <img src={heroVideoStill} alt="" />
            <CtaButton
              ctaText="Get looking"
              ctaUrl="/game"
              ctaColor="orangeDark"
              isOutlined
              withPlayIcon
              onClick={() => handleFullscreen(gaEventTracker)}
            />
          </div>
          <div className="hero__content">
            {content.homepage.introCopy.map((copy) => (
              <p key={copy} dangerouslySetInnerHTML={{ __html: copy }} />
            ))}
          </div>
        </div>
      </section>
      <section className="prizes">
        <div className="home__wrapper">
          <div className="prizes__heading">
            <img
              className="prizes__logo"
              src={poppyWhite}
              alt="Popeyes Poppy the chicken logo"
            />
            <h2 className="prizes__heading-text">Prizes</h2>
          </div>
          <img
            src={homePrizesBand}
            alt="a brass band standing on a New Orleans street"
            loading="lazy"
            decoding="async"
          />
          <div className="prizes__content">
            {content.homepage.prizes.copy.map((copy) => (
              <p
                className="prizes__content-copy"
                key={copy}
                dangerouslySetInnerHTML={{ __html: copy }}
              />
            ))}
          </div>

          <CtaButton
            ctaText="Register"
            ctaUrl="/registration"
            ctaColor="offWhite"
            onClick={() => gaEventTracker("click", "Register")}
          />

          <p className="homepage__legalese">
            <small>{content.homepage.legal}</small>
          </p>

          <button
            className="back-to-top-btn"
            type="button"
            onClick={handleBackToTop}
          >
            Back to top
          </button>

          <div className="homepage__footer-link-wrapper">
            <a
              href="/faq"
              className="homepage__faq-link"
              onClick={() => gaEventTracker("click", "FAQ")}
            >
              FAQs
            </a>
          </div>
        </div>
      </section>
    </StyledHomepageMobile>
  );
};

const StyledHomepageMobile = styled.div`
  .home {
    &__wrapper {
      align-items: center;
      color: ${({ theme }) => theme.colors.offWhite};
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      grid-area: 1 / 1 / 2 / 2;
      position: relative;
    }

    &__heading {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      justify-content: center;
      padding: 1.5rem 1.5rem 0;

      @media (min-width: 320px) {
        gap: 1.5rem;
      }

      @media (min-width: 800px) {
        padding-top: 3rem;
      }

      &-logo {
        height: 1rem;
      }

      &-poppy-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        max-width: 16.5rem;
      }

      &-poppy {
        height: 5rem;
      }

      &-text {
        font-size: 1.67rem;
        line-height: 1;
        text-align: center;
      }
    }

    &__logo {
      height: 2rem;
    }
  }

  .hero {
    background-color: ${({ theme }) => theme.colors.turquoise};
    display: grid;
    grid-template-columns: 1fr;

    .home__wrapper {
      background-color: ${({ theme }) => theme.colors.turquoise};
      margin: 1.125rem 1.5rem;
    }

    &__img-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      position: relative;

      @media (min-width: 520px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }

      @media (min-width: 800px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      a {
        position: absolute;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-bottom: 2.5rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      text-align: center;

      @media (min-width: 520px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @media (min-width: 800px) {
        padding-left: 3rem;
        padding-right: 3rem;
      }

      p {
        font-size: 1.25rem;
        font-size: 1.25rem;
        font-size: clamp(
          1.25rem,
          0.7236842105263158rem + 2.631578947368421vw,
          2.5rem
        );
        line-height: 1;
        margin: 0 auto;
      }
    }
  }

  .prizes {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: ${({ theme }) => theme.colors.orangeLight};

    &__heading {
      align-items: center;
      display: flex;
      gap: 1.5rem;
      justify-content: center;
      padding-bottom: 1rem;
      padding-top: 2rem;
    }

    &__logo {
      height: 6rem;
    }

    &__heading-text {
      display: flex;
      flex-direction: column;
      font-size: 3.25rem;
      line-height: 0.75;
      text-align: center;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding-bottom: 1.25rem;
      padding-left: 0.875rem;
      padding-right: 0.875rem;
      padding-top: 1.5rem;
      text-align: center;

      p {
        font-size: 1.125rem;
        font-size: clamp(
          1.125rem,
          0.7321428571428572rem + 1.9642857142857142vw,
          2.5rem
        );
        line-height: 1;
        margin: 0 auto;
      }
    }
  }

  .back-to-top-btn {
    margin-bottom: 1.5rem;
    color: ${({ theme }) => theme.colors.offWhite};
    font-size: 0.75rem;
  }

  .homepage__footer-link-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .homepage__faq-link {
    color: ${({ theme }) => theme.colors.offWhite};
    font-family: "ChickenSans-Bold", sans-serif;
    font-size: 1.5rem;
    position: relative;
    text-align: center;
  }

  .homepage__legalese {
    color: ${({ theme }) => theme.colors.offWhite};
    line-height: 1;
    margin-bottom: 3rem;
    text-align: center;
  }
`;

export default HomepageMobile;
