import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import bgPattern from "../../assets/images/bg-pattern-dark-green.png";
import homeIcon from "../../assets/images/home-icon.svg";

const NotFound = () => {
  return (
    <StyledNotFound
      style={{
        backgroundImage: `url(${bgPattern})`,
        backgroundPosition: "center",
      }}
    >
      <div className="not-found__inner-wrapper">
        <Link className="not-found__home-link" to="/">
          <img src={homeIcon} alt="Poppy the chicken in a house" />
        </Link>
        <h1 className="not-found__headline">
          <span className="not-found__title font-script">404: </span>{" "}
          <span>page not found.</span>
        </h1>
      </div>
    </StyledNotFound>
  );
};

const StyledNotFound = styled.section`
  align-items: center;
  display: flex;
  background-color: ${({ theme }) => theme.colors.turquoise};
  height: 100vh;
  justify-content: center;
  padding: 25px;
  width: 100%;

  .not-found {
    &__inner-wrapper {
      align-items: center;
      background-color: ${({ theme }) => theme.colors.turquoise};
      display: flex;
      justify-content: center;
      height: 100%;
      position: relative;
      width: 100%;
    }

    &__headline {
      align-items: center;
      color: ${({ theme }) => theme.colors.offWhite};
      display: flex;
      flex-direction: column;
      font-family: "ChickenSans-Regular";
      font-size: 2rem;

      @media (min-width: 690px) {
        font-size: 4rem;
        flex-direction: row;
      }
    }

    &__title {
      font-family: "ChickenScript-Regular";
      font-size: 4rem;
      margin-right: 10px;

      @media (min-width: 690px) {
        font-size: 6rem;
      }
    }

    &__home-link {
      left: 0.75rem;
      position: absolute;
      top: 0.75rem;
      width: 2rem;
      z-index: 2;

      @media (min-width: 1080px) {
        width: auto;
      }
    }
  }
`;

export default NotFound;
