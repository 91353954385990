import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { CookiesProvider } from "react-cookie";
import ReactGA from "react-ga4";
import moment from "moment-timezone"

import "./index.css";

import ComingSoonPage from "./Components/ComingSoonPage";
import GameOverPage from "./Components/GameOverPage";
import Homepage from "./Components/Homepage";
import VideoSection from "./Components/VideoSection";
import RegistrationPage from "./Components/RegistrationPage";
import FAQPage from "./Components/FAQPage";
import OfficialRulesPage from "./Components/OfficialRulesPage";
import PrivacyPolicyPage from "./Components/PrivacyPolicyPage";
import CookiesCTA from "./Components/CookiesCTA";
import NotFound from "./Components/NotFound";

const TRACKING_ID = "G-YKLYJQZGBQ";
ReactGA.initialize(TRACKING_ID);

// Get the current time in EST
const currentTime = moment().tz('America/New_York');
// Create a moment object for the target date and time in EST
const startDate = moment.tz('2023-07-24 00:00:00', 'America/New_York');
const endDate = moment.tz('2023-08-05 00:00:00', 'America/New_York');

const isBeforeStartDate = currentTime.isBefore(startDate)
const isAfterEndDate = currentTime.isAfter(endDate)

const homepage = isBeforeStartDate ? (
  <ComingSoonPage />
) : isAfterEndDate ? (
  <GameOverPage />
) : (
  <Homepage />
);

const theme = {
  colors: {
    offWhite: "#fff5e1",
    orangeLight: "#eb7300",
    orangeDark: "#f25600",
    paleYellow: "#ffe4b2",
    turquoise: "#01847e",
    darkGreen: "#005560 ",
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={homepage} />
            <Route
              path="/game"
              element={
                !(isBeforeStartDate || isAfterEndDate) ? (
                  <VideoSection />
                ) : (
                  <Navigate to="/" replace={true} />
                )
              }
            />
            <Route
              path="/registration"
              element={
                !(isBeforeStartDate || isAfterEndDate) ? (
                  <RegistrationPage />
                ) : (
                  <Navigate to="/" replace={true} />
                )
              }
            />
            <Route
              path="/faq"
              element={
                !(isBeforeStartDate || isAfterEndDate) ? (
                  <FAQPage />
                ) : (
                  <Navigate to="/" replace={true} />
                )
              }
            />
            <Route
              path="/privacy-policy"
              element={
                !(isBeforeStartDate || isAfterEndDate) ? (
                  <PrivacyPolicyPage />
                ) : (
                  <Navigate to="/" replace={true} />
                )
              }
            />
            <Route
              path="/official-rules"
              element={
                !(isBeforeStartDate || isAfterEndDate) ? (
                  <OfficialRulesPage />
                ) : (
                  <Navigate to="/" replace={true} />
                )
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        <CookiesCTA />
      </ThemeProvider>
    </CookiesProvider>
  </React.StrictMode>
);
