import React, { useEffect } from "react";
import styled from "styled-components";

import CtaButton from "../CtaButton";

import poppyOrange from "../../assets/images/poppy-orange.svg";
import popeyesLogo from "../../assets/images/popeyes-logo.svg";
import prizesBand from "../../assets/images/home-prizes-band-desktop.jpg";
import heroVideoStill from "../../assets/images/hero-video-still-desktop.jpg";

import bgPattern from "../../assets/images/bg-pattern-dark-green.png";

import { content } from "../../content";

import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const HomepageDesktop = () => {
  const gaEventTracker = useAnalyticsEventTracker("homepage");

  useEffect(() => {
    gaEventTracker("view", "homepage");
  }, [gaEventTracker]);

  return (
    <StyledHomepageDesktop>
      <img
        className="header__logo"
        src={popeyesLogo}
        alt="Popeyes logo"
        loading="lazy"
        decoding="async"
      />
      <section className="homepage__grid-section homepage__grid-section--hero">
        <div className="homepage__hero-grid">
          <div>
            <div className="home__heading-poppy-wrapper">
              <img
                className="home__heading-poppy"
                src={poppyOrange}
                alt="Popeyes Poppy the chicken logo"
              />
              <h1 className="home__heading-text">
                <span className="font-script">Poppy</span> is Ready to Play.
                <br />
                Are You?
              </h1>
            </div>
            <p className="hero__content-copy">
              {content.homepage.introCopy.map((copy) => (
                <span
                  key={copy}
                  dangerouslySetInnerHTML={{ __html: copy + " " }}
                />
              ))}
            </p>
          </div>
          <div
            className="hero__img-wrapper"
            style={{
              backgroundImage: `url(${bgPattern})`,
              backgroundPosition: "center",
            }}
          >
            <img src={heroVideoStill} alt="" />
            <CtaButton
              ctaText="Get looking"
              ctaUrl="/game"
              ctaColor="orangeDark"
              isOutlined
              withPlayIcon
              onClick={() => gaEventTracker("click", "get looking")}
            />
          </div>
          <div>
            <img
              className="home__logo"
              src={popeyesLogo}
              alt="Popeyes logo"
              loading="lazy"
              decoding="async"
            />
          </div>
        </div>

        <div className="homepage__prizes-grid">
          <div>
            <img
              src={prizesBand}
              alt="A brass band standing on a New Orleans street."
            />
          </div>
          <div>
            <div className="homepage__prizes-copy-wrapper">
              <img
                className="home__heading-poppy"
                src={poppyOrange}
                alt="Popeyes Poppy the chicken logo"
              />
              <h2>Prizes</h2>
              {content.homepage.prizes.copy.map((copy) => (
                <p
                  className="homepage__hero-content"
                  key={copy}
                  dangerouslySetInnerHTML={{ __html: copy }}
                />
              ))}
            </div>
          </div>
          <div>
            <CtaButton
              ctaText="Register"
              ctaUrl="/registration"
              ctaColor="offWhite"
              onClick={() => gaEventTracker("click", "Register")}
            />
          </div>
        </div>
        <p className="homepage__legalese">
          <small>{content.homepage.legal}</small>
        </p>

        <div className="homepage__footer-link-wrapper">
          <a
            href="/faq"
            className="homepage__faq-link"
            onClick={() => gaEventTracker("click", "FAQ")}
          >
            FAQs
          </a>
        </div>
      </section>
    </StyledHomepageDesktop>
  );
};

const StyledHomepageDesktop = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.orangeLight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 3rem 6rem;

  @media (min-width: 1440px) {
    padding: 3rem 12rem 6rem;
  }

  .header {
    &__logo {
      margin-bottom: 3rem;
    }
  }

  .home {
    &__heading {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      justify-content: center;
      padding: 1.5rem 2rem 0;

      @media (min-width: 320px) {
        gap: 2.5rem;
      }

      @media (min-width: 800px) {
        padding-top: 3rem;
      }

      &-poppy-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        max-width: 20rem;
      }

      &-poppy {
        height: 4rem;
      }

      &-text {
        font-size: 2rem;
        line-height: 1;
        text-align: center;
      }
    }
  }

  .homepage__hero-grid {
    display: grid;
    grid-template-columns: 45% 55%;
    grid-template-rows: min-content 7.5rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 64.625rem;
    position: relative;

    .find-me-text-bubble {
      display: none;
      left: 26%;
      position: absolute;
      top: 0;
      width: 12rem;

      @media (min-width: 1800px) {
        display: block;
      }
    }

    > div {
      padding: 1.5rem;
    }

    > div:nth-of-type(1) {
      align-items: center;
      background-color: ${({ theme }) => theme.colors.paleYellow};
      color: ${({ theme }) => theme.colors.orangeDark};
      display: flex;
      flex-direction: column;
      justify-content: center;

      .poppy {
        margin-left: auto;
        margin-right: auto;
        max-width: 272px;
        width: 100%;
      }

      p {
        font-size: 1.5rem;
        line-height: 1;
        margin: 0 auto;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        text-align: center;
      }
    }

    > div:nth-of-type(2) {
      align-items: center;
      background-color: ${({ theme }) => theme.colors.turquoise};
      display: flex;
      flex-direction: column;
      grid-area: span 2;
      justify-content: center;
      position: relative;
      text-align: center;

      a {
        position: absolute;
      }
    }

    > div:nth-of-type(3) {
      align-items: center;
      background-color: ${({ theme }) => theme.colors.orangeDark};
      display: flex;
      justify-content: center;
      padding-bottom: 2rem;
      padding-top: 2rem;
    }
  }

  .homepage__poppy-wrapper {
    align-items: center;
    display: flex;
    width: 100%;
  }

  .homepage__prizes-grid {
    display: grid;
    grid-template-columns: 60% 40%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: .5rem;
    max-width: 64.625rem;
    position: relative;

    > div:nth-of-type(1) {
      grid-row: span 2;
    }

    > div:nth-of-type(2) {
      align-items: center;
      background-color: ${({ theme }) => theme.colors.paleYellow};
      color: ${({ theme }) => theme.colors.orangeDark};
      display: flex;
      justify-content: center;
      padding: 1.5rem 3rem;
      text-align: center;

      h2 {
        font-size: 3rem;
      }

      p {
        font-size: 1.5rem;
        line-height: 1;
        margin: 0 auto;
        margin-top: 1.5rem;
        max-width: 34rem;

        @media (min-width: 1480px) {
          margin-bottom: 0;
        }
      }
    }

    > div:nth-of-type(3) {
      align-items: center;
      background-color: ${({ theme }) => theme.colors.orangeDark};
      display: flex;
      justify-content: center;
      padding: 1rem;
    }
  }

  .homepage__prizes-copy-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .homepage__footer-link-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .homepage__faq-link {
    color: ${({ theme }) => theme.colors.offWhite};
    font-family: "ChickenSans-Bold", sans-serif;
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-align: center; 100%;
  }

  .homepage__legalese {
    color: ${({ theme }) => theme.colors.offWhite};
    line-height: 1;
    margin-bottom: 3rem;
    max-width: 64.625rem;
    text-align: center;
  }

  h1 {
    font-size: 4rem;
  }
`;

export default HomepageDesktop;
