import React, { Fragment, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import PreloadImage from "react-preload-image";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useCookies } from "react-cookie";

import CtaButton from "../CtaButton";
import RotateDevice from "../RotateDevice";

import useOrientation from "../../hooks/useOrientation";

import homeIcon from "../../assets/images/home-icon.svg";
import prizesBand from "../../assets/images/registration-band.jpg";
import bgPattern from "../../assets/images/bg-pattern-orange.png";

import { content } from "../../content";

import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const RegistrationPage = () => {
  const [submissionErrors, setSubmissionErrors] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const orientation = useOrientation();
  const [cookies, setCookie] = useCookies(["registration"]);
  const checkRef = useRef();

  const alreadyRegistered = cookies.poppyRegistered;

  const date = new Date();
  const tomorrow = date.setDate(date.getDate() + 1);
  const tomorrowMidnight = new Date(tomorrow).setHours(0, 0, 0, 0);

  const gaEventTracker = useAnalyticsEventTracker("register");
  const gaEventTrackerSubmitted = useAnalyticsEventTracker(
    "registerConfirmation"
  );

  const handleErrorCheck = (event) => {
    event.preventDefault();

    let currentErrors;
    const formData = new FormData(event.target);
    const entries = Object.fromEntries(formData.entries());

    const isEmailValid = validateEmail(entries.email);

    // clear errors
    setSubmissionErrors();

    if (!entries.firstName) {
      currentErrors = {
        ...currentErrors,
        firstName: "First Name Is Required",
      };
    }
    if (!entries.lastName) {
      currentErrors = {
        ...currentErrors,
        lastName: "Last Name Is Required",
      };
    }
    if (!entries.addressLine1) {
      currentErrors = {
        ...currentErrors,
        addressLineOne: "Address is Required",
      };
    }
    if (!entries.city) {
      currentErrors = {
        ...currentErrors,
        city: "City is Required",
      };
    }
    if (!entries.country) {
      currentErrors = {
        ...currentErrors,
        country: "Country is Required",
      };
    }
    if (!entries.state) {
      currentErrors = {
        ...currentErrors,
        state: "State is Required",
      };
    }

    if (!entries.postalCode || entries.postalCode.length !== 5) {
      currentErrors = {
        ...currentErrors,
        postal: "Postal is Required",
      };
    }
    if (!entries.email || !isEmailValid) {
      currentErrors = {
        ...currentErrors,
        email: "Email is Required",
      };
    }
    if (!checkRef.current.checked) {
      currentErrors = {
        ...currentErrors,
        accept: "Required",
      };
    }

    setSubmissionErrors(currentErrors);
    gaEventTracker("click", "register error");

    if (!currentErrors) {
      handleSubmit(entries);
      gaEventTracker("click", "register valid");
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSubmit = async (entries) => {
    try {
      const response = await fetch("/api/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(entries),
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
    setCookie("poppyRegistered", true, {
      path: "/",
      expires: new Date(tomorrowMidnight),
    });
    setIsSubmitted(true);
  };

  const hasNameError =
    submissionErrors?.firstName || submissionErrors?.lastName;
  const hasAddressError =
    submissionErrors?.addressLineOne ||
    submissionErrors?.city ||
    submissionErrors?.state ||
    submissionErrors?.postal ||
    submissionErrors?.country;

  useEffect(() => {
    if (isSubmitted) {
      gaEventTrackerSubmitted("view", "registration sent");
    } else if (alreadyRegistered) {
      gaEventTrackerSubmitted("view", "registration denied");
    } else {
      gaEventTracker("view", "register");
    }
  }, [gaEventTracker, gaEventTrackerSubmitted, isSubmitted, alreadyRegistered]);

  if (orientation === "landscape" && isMobile) {
    return <RotateDevice orientation={orientation} />;
  }

  return (
    <StyledRegistrationPage>
      <div className="registration-page__wrapper">
        <Link
          className="registration-page__home-link"
          to="/"
          onClick={() => gaEventTrackerSubmitted("click", "home")}
        >
          <img src={homeIcon} alt="Poppy the chicken in a house" />
        </Link>
        <div className="registration-page__left-col">
          <PreloadImage
            src={prizesBand}
            alt="A brass band standing on a New Orleans street."
            style={{
              position: "relative",
              width: "100%",
              height: "600px",
              backgroundColor: "#222222",
            }}
          />
        </div>
        <div className="registration-page__right-col">
          <div
            className={`registration-page__right-col-wrapper ${
              alreadyRegistered || (!alreadyRegistered && isSubmitted)
                ? "registration-page__right-col-wrapper--centered"
                : ""
            }`}
            style={{
              gap: alreadyRegistered || isSubmitted ? "1.5rem" : 0,
              height:
                alreadyRegistered || isSubmitted
                  ? "calc(100vh - 3rem)"
                  : "100%",
            }}
          >
            <div className="registration-page__copy">
              <h1>
                {alreadyRegistered
                  ? "You've already registered today!"
                  : "You did it!"}
              </h1>
              {alreadyRegistered && (
                <div className="registration-page__submitted-copy">
                  <p>{content.registrationPage.alreadyRegisteredCopy}</p>
                </div>
              )}
              {!alreadyRegistered && isSubmitted && (
                <div className="registration-page__submitted-copy">
                  {content.registrationPage.postRegistrationCopy.map((copy) => (
                    <p
                      key={copy}
                      dangerouslySetInnerHTML={{
                        __html: copy,
                      }}
                    />
                  ))}
                </div>
              )}
              {!alreadyRegistered && !isSubmitted && (
                <p>{content.registrationPage.preRegistrationCopy}</p>
              )}
            </div>

            {!alreadyRegistered && !isSubmitted && (
              <Fragment>
                <form className="form" onSubmit={handleErrorCheck}>
                  <fieldset>
                    <legend className="form__section-heading">Full Name</legend>
                    <ul className="form__inputs-list">
                      <li
                        className={`form__inputs-item half ${
                          hasNameError && "has-error"
                        }`}
                      >
                        <label htmlFor="firstName">First name</label>
                        <input
                          className={`form__inputs-input form__inputs-input--text ${
                            submissionErrors?.firstName && "has-error"
                          }`}
                          type="text"
                          id="firstName"
                          name="firstName"
                          placeholder="First Name"
                          onChange={() =>
                            setSubmissionErrors((previous) => {
                              return {
                                ...previous,
                                firstName: "",
                              };
                            })
                          }
                        />
                        {submissionErrors?.firstName && (
                          <p className="form__error">
                            {submissionErrors?.firstName}
                          </p>
                        )}
                      </li>
                      <li
                        className={`form__inputs-item half ${
                          hasNameError && "has-error"
                        }`}
                      >
                        <label htmlFor="lastName">Last name</label>
                        <input
                          className={`form__inputs-input form__inputs-input--text ${
                            submissionErrors?.lastName && "has-error"
                          }`}
                          type="text"
                          id="lastName"
                          name="lastName"
                          placeholder="Last Name"
                          onChange={() =>
                            setSubmissionErrors((previous) => {
                              return {
                                ...previous,
                                lastName: "",
                              };
                            })
                          }
                        />
                        {submissionErrors?.lastName && (
                          <p className="form__error">
                            {submissionErrors?.lastName}
                          </p>
                        )}
                      </li>
                    </ul>
                  </fieldset>
                  <fieldset>
                    <legend className="form__section-heading">Address</legend>
                    <ul className="form__inputs-list">
                      <li className="form__inputs-item">
                        <label htmlFor="addressLine1">
                          Street Address Line 1
                        </label>
                        <input
                          className={`form__inputs-input form__inputs-input--text ${
                            submissionErrors?.addressLineOne && "has-error"
                          }`}
                          type="text"
                          id="addressLine1"
                          name="addressLine1"
                          placeholder="Street Address Line 1"
                          onChange={() =>
                            setSubmissionErrors((previous) => {
                              return {
                                ...previous,
                                addressLineOne: "",
                              };
                            })
                          }
                        />
                      </li>
                      <li className="form__inputs-item">
                        <label htmlFor="addressLine2">
                          Street Address Line 2
                        </label>
                        <input
                          className="form__inputs-input form__inputs-input--text"
                          type="text"
                          id="addressLine2"
                          name="addressLine2"
                          placeholder="Street Address Line 2"
                        />
                      </li>
                      <li className="form__inputs-item half">
                        <label htmlFor="city">City</label>
                        <input
                          className={`form__inputs-input form__inputs-input--text ${
                            submissionErrors?.city && "has-error"
                          }`}
                          type="text"
                          id="city"
                          name="city"
                          placeholder="City"
                          onChange={() =>
                            setSubmissionErrors((previous) => {
                              return {
                                ...previous,
                                city: "",
                              };
                            })
                          }
                        />
                      </li>
                      <li className="form__inputs-item half">
                        <label htmlFor="state">State/Province</label>
                        <input
                          className={`form__inputs-input form__inputs-input--text ${
                            submissionErrors?.state && "has-error"
                          }`}
                          type="text"
                          id="state"
                          name="state"
                          placeholder="State/Province"
                          onChange={() =>
                            setSubmissionErrors((previous) => {
                              return {
                                ...previous,
                                state: "",
                              };
                            })
                          }
                        />
                      </li>
                      <li
                        className={`form__inputs-item half ${
                          hasAddressError && "has-error"
                        }`}
                      >
                        <label htmlFor="postalCode">Postal Code</label>
                        <input
                          className={`form__inputs-input form__inputs-input--text ${
                            submissionErrors?.postal && "has-error"
                          }`}
                          type="text"
                          id="postalCode"
                          name="postalCode"
                          placeholder="Postal Code"
                          onChange={() =>
                            setSubmissionErrors((previous) => {
                              return {
                                ...previous,
                                postal: "",
                              };
                            })
                          }
                        />
                        {hasAddressError && (
                          <p className="form__error">Fields Are Required</p>
                        )}
                      </li>
                      <li
                        className={`form__inputs-item half ${
                          hasAddressError && "has-error"
                        }`}
                      >
                        <label htmlFor="country">Country</label>
                        <input
                          className={`form__inputs-input form__inputs-input--text ${
                            submissionErrors?.country && "has-error"
                          }`}
                          type="text"
                          id="country"
                          name="country"
                          placeholder="Country"
                          onChange={() =>
                            setSubmissionErrors((previous) => {
                              return {
                                ...previous,
                                country: "",
                              };
                            })
                          }
                        />
                      </li>
                    </ul>
                  </fieldset>
                  <fieldset>
                    <legend className="form__section-heading">Email</legend>
                    <ul className="form__inputs-list">
                      <li
                        className={`form__inputs-item ${
                          submissionErrors?.email && "has-error"
                        }`}
                      >
                        <label htmlFor="email">Email</label>
                        <input
                          className={`form__inputs-input form__inputs-input--text ${
                            submissionErrors?.email && "has-error"
                          }`}
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Email Address"
                          onChange={() =>
                            setSubmissionErrors((previous) => {
                              return {
                                ...previous,
                                email: "",
                              };
                            })
                          }
                        />
                        {submissionErrors?.email && (
                          <p className="form__error">
                            {submissionErrors?.email}
                          </p>
                        )}
                      </li>
                    </ul>
                  </fieldset>
                  <ul className="form__inputs-list">
                    <li
                      className={`form__inputs-item form__inputs-item--checkbox ${
                        submissionErrors?.accept && "has-error"
                      }`}
                    >
                      <div>
                        <input
                          ref={checkRef}
                          className={`form__inputs-input form__inputs-input--checkbox ${
                            submissionErrors?.accept && "has-error"
                          }`}
                          id="terms"
                          name="terms"
                          type="checkbox"
                          value="accepted"
                          onChange={() =>
                            setSubmissionErrors((previous) => {
                              return {
                                ...previous,
                                accept: "",
                              };
                            })
                          }
                        />
                        <label
                          htmlFor="terms"
                          className="form__inputs-label--checkbox"
                        >
                          I agree to the{" "}
                          <Link
                            to="/official-rules"
                            className="form__inputs-input--link"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              gaEventTracker("click", "official rules")
                            }
                          >
                            Official Rules
                          </Link>{" "}
                          and{" "}
                          <Link
                            to="/privacy-policy"
                            className="form__inputs-input--link"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              gaEventTracker("click", "privacy policy")
                            }
                          >
                            PLK Privacy Policy
                          </Link>
                        </label>
                      </div>
                      {!checkRef?.current?.checked && (
                        <p className="form__error">
                          {submissionErrors?.accept}
                        </p>
                      )}
                    </li>
                    <li className="form__inputs-item">
                      <p className="centered">
                        <small>{content.registrationPage.legalCopy}</small>
                      </p>
                    </li>
                    <li className="form__inputs-item centered">
                      <CtaButton
                        className="form__inputs-submit"
                        ctaText="Register"
                        type="submit"
                        ctaColor="darkGreen"
                        isLarge
                      />
                    </li>
                  </ul>
                </form>
              </Fragment>
            )}

            {(alreadyRegistered || isSubmitted) && (
              <CtaButton
                ctaText="Keep playing"
                ctaUrl="/"
                ctaColor="darkGreen"
                isLarge
                onClick={() => gaEventTrackerSubmitted("click", "keep playing")}
              />
            )}
          </div>
        </div>
      </div>
    </StyledRegistrationPage>
  );
};

const StyledRegistrationPage = styled.section`
  .registration-page {
    &__home-link {
      left: 0.75rem;
      position: absolute;
      top: 0.75rem;
      width: 2rem;
      z-index: 2;

      @media (min-width: 1080px) {
        width: auto;
      }
    }

    &__wrapper {
      background-color: ${({ theme }) => theme.colors.orangeLight};
      position: relative;

      @media (min-width: 1080px) {
        display: flex;
        height: 100vh;
      }

      > div {
        flex-basis: 50%;
        padding: 1.5rem;

        @media (min-width: 1080px) {
          padding: 1.5rem 3rem;
        }
      }
    }

    &__left-col {
      display: none;

      @media (min-width: 1080px) {
        align-items: center;
        background-color: #00b2a9;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        justify-content: center;
      }
    }

    &__right-col {
      background-image: url(${bgPattern});
      color: ${({ theme }) => theme.colors.offWhite};
      overflow-y: hidden;

      @media (min-height: 940px) {
        height: 100vh;
      }

      @media (orientation: landscape) {
        height: auto;
      }

      @media (min-width: 1080px) {
        background-image: none;
      }

      &::before {
        background-color: ${({ theme }) => theme.colors.orangeLight};
        content: "";
        height: 60%;
        left: -1.5rem;
        position: absolute;
        width: calc(50% + 1.5rem);
        top: -1.5rem;

        @media (min-width: 1080px) {
          content: none;
        }
      }

      &::after {
        background-color: ${({ theme }) => theme.colors.orangeLight};
        bottom: 0;
        content: "";
        height: 60%;
        position: absolute;
        right: 0;
        width: calc(50% + 1.5rem);

        @media (min-width: 1080px) {
          content: none;
        }
      }

      &-wrapper {
        align-items: center;
        background-color: ${({ theme }) => theme.colors.orangeLight};
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        overflow-y: auto;
        position: relative;
        width: 100%;
        z-index: 1;

        &--centered {
          justify-content: center;
        }

        @media (min-width: 1080px) {
          justify-content: normal;

          &--centered {
            justify-content: center;
          }
        }
      }
    }

    &__copy {
      h1 {
        font-family: "ChickenSans-Bold", sans-serif;
        font-size: 2.75rem;
        line-height: 1;
        margin-bottom: 1rem;
        text-align: center;
      }

      p {
        font-family: "ChickenSans-Bold", sans-serif;
        font-size: 1.25rem;
        line-height: 1;
        margin-left: auto;
        margin-right: auto;
        max-width: 15rem;
        text-align: center;

        @media (min-width: 1080px) {
          max-width: 27rem;
        }

        + p {
          margin-top: 1rem;
        }
      }
    }
  }

  .form {
    margin-top: 1.5rem;
    max-width: 30rem;
    width: 90%;

    @media (min-width: 1080px) {
      width: 100%;
    }

    fieldset + * {
      margin-top: 1.5rem;
    }

    &__error {
      font-size: 0.875rem;
      font-style: italic;
      transform: translateX(1rem);
    }

    &__section-heading {
      color: ${({ theme }) => theme.colors.darkGreen};
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
    }

    &__inputs-list {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;

      @media (min-width: 1080px) {
        gap: 1.5rem;
      }
    }

    &__inputs-item {
      position: relative;
      width: 100%;

      &.half {
        width: calc(50% - 0.5rem);

        @media (min-width: 1080px) {
          width: calc(50% - 0.75rem);
        }
      }

      &.centered {
        display: flex;
        justify-content: center;
      }

      &--checkbox {
        > div {
          align-items: center;
          display: flex;
          gap: 0.75rem;
          justify-content: center;
        }
      }

      label:not(.form__inputs-label--checkbox) {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
      }
    }

    &__inputs-input {
      &.has-error {
        -webkit-appearance: none;
        appearance: none;
        border: 3px solid white;
        box-shadow: inset 0px 0px 0px 3px red;
      }

      &.form__inputs-input--checkbox {
        &.has-error {
          border: 1px solid white;
        }
      }

      &--link {
        color: #fff;
      }

      &--text {
        background-color: ${({ theme }) => theme.colors.offWhite};
        border: 0;
        border-radius: 3rem;
        color: #005560;
        font-family: "ChickenSans-Regular", sans-serif;
        font-size: 1rem;
        padding: 1rem 1.5rem;
        width: 100%;
      }

      &--checkbox {
        -webkit-appearance: none;
        appearance: none;
        background-color: ${({ theme }) => theme.colors.offWhite};
        border-radius: 0.25rem;
        color: currentColor;
        display: grid;
        flex-shrink: 0;
        font: inherit;
        height: 1.25rem;
        margin: 0;
        place-content: center;
        transform: translateY(0.125rem);
        width: 1.25rem;

        &::before {
          content: "";
          width: 0.8125rem;
          height: 0.8125rem;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          box-shadow: inset 0.75rem 0.75rem
            ${({ theme }) => theme.colors.orangeDark};
        }

        &:checked::before {
          transform: scale(1);
        }
      }
    }

    &__inputs-submit {
      margin-bottom: 0.75rem;
      margin-top: 0.75rem;
    }
  }

  ::placeholder {
    color: #005560;
    font-family: "ChickenSans-Regular", sans-serif;
    font-size: 0.875rem;
    opacity: 1;
  }
`;

export default RegistrationPage;
