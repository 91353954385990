import React, { useState } from "react";
import styled from "styled-components";

import CtaButton from "../CtaButton";

import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const CookiesCTA = () => {
  const gaEventTracker = useAnalyticsEventTracker("homepage");

  const [bannerClicked, setBannerbannerClicked] = useState(
    window.sessionStorage.getItem("bannerClicked")
  );

  const handleButtonClick = () => {
    gaEventTracker("click", "Accept Cookies");
    window.sessionStorage.setItem("bannerClicked", true);
    setBannerbannerClicked(window.sessionStorage.getItem("bannerClicked"));
  };

  return bannerClicked !== "true" ? (
    <StyledCookiesCTA>
      <div className="cookies-cta__text-wrapper">
        <p>
          This website may use cookies to personalize your experience, and to
          analyze site traffic and for our advertising purposes. To learn more
          about what information we collect through our site, how we use it and
          the choices you may have regarding our use,{" "}
          <span className="no-wrap">
            see our{" "}
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              privacy policy
            </a>
            .
          </span>
        </p>
      </div>
      <CtaButton
        className="cookie-cta-button"
        ctaText={"OK"}
        ctaColor="orangeLight"
        onClick={handleButtonClick}
      />
    </StyledCookiesCTA>
  ) : null;
};

const StyledCookiesCTA = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.offWhite};
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 6;

  @media (min-width: 960px) {
    padding: 75px 0;
  }

  a {
    color: #000;
  }

  p {
    width: 75%;
  }

  .cookies-cta__text-wrapper {
    display: flex;
    height: 100px;
    justify-content: center;
    overflow: scroll;
    text-align: center;

    @media (min-width: 960px) {
      align-items: center;
      height: auto;
      overflow: auto;
    }
  }

  .cookie-cta-button {
    margin-top: 50px;
    padding: 0 50px;
  }
`;

export default CookiesCTA;
