import React, { useEffect } from "react";
import styled from "styled-components";

import comingSoonHeader from "../../assets/images/coming-soon-page-header.svg";
import popeyesLogo from "../../assets/images/popeyes-logo.svg";

import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const ComingSoonPage = () => {
  const gaEventTracker = useAnalyticsEventTracker("comingSoon");

  useEffect(() => {
    gaEventTracker("view", "coming soon");
  }, [gaEventTracker]);

  return (
    <StyledComingSoonPage>
      <div className="coming-soon__content-wrapper">
        <div>
          <img
            className="coming-soon__header-img"
            src={comingSoonHeader}
            alt="Find Poppy"
          />
        </div>
        <div className="coming-soon__copy-wrapper">
          <h1>7/24/2023</h1>
          <p>Come back to play on July 24th.</p>
        </div>
      </div>
      <div>
        <img
          className="home__logo"
          src={popeyesLogo}
          alt="Popeyes logo"
          loading="lazy"
          decoding="async"
        />
      </div>
    </StyledComingSoonPage>
  );
};

const StyledComingSoonPage = styled.section`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.orangeLight};
  color: ${({ theme }) => theme.colors.offWhite};
  display: grid;
  gap: 3rem;
  grid-template-rows: auto max-content;
  height: 100vh;
  justify-items: center;
  padding: 1.5rem;

  @media (min-width: 50rem) {
    padding: 3rem;
  }

  .coming-soon {
    &__content-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      justify-content: center;
      padding-top: 1.5rem;
    }

    &__header-img {
      margin-left: auto;
      margin-right: auto;
      max-width: 30rem;
      width: 100%;

      @media (orientation: landscape) and (max-width: 50rem) {
        width: 40%;
      }
    }

    &__copy-wrapper {
      line-height: 1.25;
      text-align: center;

      h1 {
        font-size: 2rem;
        font-size: clamp(2rem, 1.5rem + 2.5vw, 4rem);
      }

      p {
        font-size: 1.25rem;
        font-size: clamp(1.25rem, 1rem + 1.25vw, 2.25rem);
      }
    }
  }
`;

export default ComingSoonPage;
