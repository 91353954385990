import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";

import ctaPlayIcon from "../../assets/images/icon-play-outlined.svg";

const CtaButton = ({
  className,
  ctaText,
  ctaUrl,
  ctaColor,
  isExternal,
  isLarge,
  isOutlined,
  onClick,
  type,
  withPlayIcon,
}) => {
  if (ctaUrl) {
    return (
      <StyledLink
        className={className}
        to={ctaUrl}
        $ctaColor={ctaColor}
        $isLarge={isLarge}
        $isOutlined={isOutlined}
        onClick={onClick}
        target={isExternal ? "_blank" : "_self"}
      >
        <span>{ctaText}</span>{" "}
        {withPlayIcon && <img src={ctaPlayIcon} alt="" />}
      </StyledLink>
    );
  } else {
    return (
      <StyledButton
        className={className}
        onClick={onClick}
        $ctaColor={ctaColor}
        $isLarge={isLarge}
        $isOutlined={isOutlined}
        type={type}
      >
        <span>{ctaText}</span>{" "}
        {withPlayIcon && <img src={ctaPlayIcon} alt="" />}
      </StyledButton>
    );
  }
};

CtaButton.propTypes = {
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string,
  ctaColor: PropTypes.oneOf([
    "darkGreen",
    "orangeLight",
    "orangeDark",
    "offWhite",
  ]),
  isOutlined: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["button", "submit"]),
  withPlayIcon: PropTypes.bool,
};

CtaButton.defaultProps = {
  ctaUrl: "",
  ctaColor: "orange",
  isOutlined: false,
  onClick: () => {},
  type: "button",
  withPlayIcon: false,
};

const colorMap = {
  offWhite: "#fff5e1",
  orangeLight: "#eb7300",
  orangeDark: "#f25600",
  darkGreen: "#005560",
};

const StyledButton = styled.button`
  align-items: center;
  background-color: ${(props) => colorMap[props.$ctaColor]};
  border: ${(props) => props.$isOutlined && "2px solid #fff5e1"};
  border-radius: 3rem;
  color: ${({ theme }) => theme.colors.offWhite};
  cursor: pointer;
  display: inline-flex;
  font-size: 1.125rem;
  gap: 1rem;
  justify-self: center;
  padding: ${(props) => (props.$isLarge ? "0.75rem 1.5rem" : "0.5rem 1.25rem")};
  transition: transform 0.2s ease-out;

  @media (min-width: 820px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1080px) {
    border: ${(props) => props.$isOutlined && "4px solid #fff5e1"};
    font-size: 1.75rem;
  }

  &:focus,
  &:hover {
    transform: scale(1.05);
  }

  span {
    transform: translateY(-2px);
  }

  img {
    height: 2rem;
  }
`;

const StyledLink = styled(Link)`
  align-items: center;
  background-color: ${(props) => colorMap[props.$ctaColor]};
  border: ${(props) => props.$isOutlined && "3px solid #fff5e1"};
  border-radius: 3rem;
  color: ${(props) => (props.$ctaColor === "offWhite" ? "#f25600" : "#fff5e1")};
  display: inline-flex;
  font-size: 1rem;
  gap: 0.75rem;
  justify-self: center;
  padding: ${(props) => (props.$isLarge ? "0.75rem 1.5rem" : "0.5rem 1.25rem")};
  text-align: center;
  text-decoration: none;
  transition: transform 0.2s ease-out;

  @media (min-width: 820px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1080px) {
    border: ${(props) => props.$isOutlined && "4px solid #fff5e1"};
    font-size: 1.75rem;
  }

  &:focus,
  &:hover {
    transform: scale(1.05);
  }

  span {
    transform: translateY(-2px);
  }

  img {
    height: 1.25rem;
  }
`;

export default CtaButton;
