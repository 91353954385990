import React from "react";
import styled from "styled-components";

import topLeftArrow from "../../../assets/images/start-overlay-arrow-top-left.svg";

const UpperLeftLarge = () => {
  return (
    <StyledUpperLeft>
      <p className="start-overlay__copy">
        The Poppy tracker keeps track
        <br />
        of all the Poppys you’ve found.
      </p>
      <img src={topLeftArrow} alt="arrow pointing to the current score" />
    </StyledUpperLeft>
  );
};

const StyledUpperLeft = styled.div`
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.offWhite};
  display: flex;
  padding-top: 3rem;
  left: 25px;
  position: absolute;
  top: 120px;
  width: max-content;

  @media (min-width: 1200px) {
    padding-top: 1rem;
    top: 150px;
  }

  img {
    height: 5rem;
    transform: translate(-1rem, -70%);

    @media (min-width: 1200px) {
      height: 7rem;
      transform: translate(-1.5rem, -65%);
    }
  }

  .start-overlay__copy {
    font-size: 0.75rem;
    line-height: 1.2;

    @media (min-width: 1200px) {
      font-size: 1rem;
    }
    max-width: 20rem;
  }
`;

export default UpperLeftLarge;
