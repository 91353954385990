import React from "react";
import styled from "styled-components";

import bottomArrow from "../../../assets/images/start-overlay-arrow-bottom.svg";

const LowerRightLarge = () => {
  return (
    <StyledLowerRight>
      <img src={bottomArrow} alt="arrow pointing to the video scrubber" />
      <div>
        <p className="start-overlay__copy">
          Use the scrubber to go frame
          <br />
          by frame of the video.
        </p>
      </div>
    </StyledLowerRight>
  );
};

const StyledLowerRight = styled.div`
  align-self: end;
  bottom: 8%;
  color: ${({ theme }) => theme.colors.offWhite};
  display: flex;
  gap: 1rem;
  justify-self: end;
  position: absolute;
  right: 35px;
  z-index: 3;

  img {
    height: 3rem;

    @media (min-width: 960px) {
      height: 3.5rem;
    }

    @media (min-width: 1200px) {
      height: 8rem;
    }
  }

  .start-overlay__copy {
    font-size: 0.75rem;
    line-height: 1.2;

    @media (min-width: 1200px) {
      font-size: 1rem;
    }
    max-width: 20rem;
  }
`;

export default LowerRightLarge;
