import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import styled from "styled-components";
import classNames from "classnames";
import InputRange from "react-input-range";
import { isMobile, isIOS } from "react-device-detect";

import CtaButton from "../CtaButton";

import UpperRightLarge from "./StartGameOverlayLarge/UpperRightLarge";
import UpperLeftLarge from "./StartGameOverlayLarge/UpperLeftLarge";
import LowerRightLarge from "./StartGameOverlayLarge/LowerRightLarge";
import LowerLeftLarge from "./StartGameOverlayLarge/LowerLeftLarge";

import PrizesOverlay from "./PrizesOverlay";
import StartgameOveryLarge from "./StartGameOverlayLarge";
import StartgameOverySmall from "./StartGameOverlaySmall";
import EndGameOverlay from "./EndGameOverlay";
import RotateDevice from "../RotateDevice";

import handleExplodeParticles from "../Explosion";

import * as animationsData from "../../assets/standaloneTargets.json";
import backgroundMovie from "../../assets/NEW-popeyes.mp4";
import foundPoppySound from "../../assets/found-poppy.mp3";
import foundAllSound from "../../assets/found-all-poppys.mp3";
import { Howl } from "howler";
import logo from "../../assets/images/poppy-white.svg";
import enterNowRibbon from "../../assets/images/enter-now-ribbon-light.svg";
import playIcon from "../../assets/images/play-icon.svg";
import pauseIcon from "../../assets/images/pause-icon.svg";

import useOrientation from "../../hooks/useOrientation";
import useWindowSize from "../../hooks/useWindowSize";
import useLocalStorage from "../../hooks/useLocalStorage";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const ids = [
  "TARGET1",
  "TARGET2",
  "TARGET3",
  "TARGET4",
  "TARGET5",
  "TARGET6",
  "TARGET7",
  "TARGET8",
  "TARGET9",
  "TARGET10",
  "TARGET11",
  "TARGET12",
  "TARGET13",
  "TARGET14",
  "TARGET15",
  "TARGET16",
  "TARGET17",
  "TARGET18",
  "TARGET19a",
  "TARGET19b",
  "TARGET20",
];

const playableFoundPoppySound = new Howl({
  src: [foundPoppySound],
});

const playableFoundAllSound = new Howl({
  src: [foundAllSound],
});

const totalNumberOfPoppysAvailable = 20;

const VideoSection = () => {
  const vidRef = useRef();
  const lottieRef = useRef();
  const controlsRef = useRef();
  const scrubbing = useRef(false);
  const returnToPlaying = useRef(false);
  const hasClicked = useRef(false);

  const [videoPlaying, setVideoPlaying] = useState(false);
  const [showStart, setShowStart] = useState(true);
  const [showEnd, setShowEnd] = useState(false);
  const [showPrizeList, setShowPrizeList] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const [poppyFound, setPoppyFound] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);
  const [controlsWidth, setControlsWidth] = useState(0);

  const [foundPoppys, setFoundPoppys] = useLocalStorage("foundPoppys", "");
  const [totalClicked, setTotalClicked] = useState(() => {
    const foundPoppysFromStorage = localStorage.getItem("foundPoppys");
    const foundPoppysArray = JSON.parse(foundPoppysFromStorage) || [];
    return foundPoppysArray.length;
  });

  const gaEventTrackerHowToPlay = useAnalyticsEventTracker("howToPlay");
  const gaEventTrackerVideo = useAnalyticsEventTracker("video");
  const gaEventTrackerNiceTry = useAnalyticsEventTracker("endscreen_nicetry");
  const gaEventTrackerMyPrizes = useAnalyticsEventTracker("myPrizes");

  const orientation = useOrientation();
  const startEndButtonText = showStart ? "play game" : "How to play";

  const handleVideoEnded = () => {
    lottieRef.current.pause();
    setVideoPlaying(false);
    setShowEnd(true);
  };

  const handleSetToPause = () => {
    vidRef.current?.pause();
    lottieRef.current?.pause();
    setVideoPlaying(false);
  };

  const handleSetToPlay = () => {
    vidRef.current?.play();
    lottieRef.current?.play();
    setVideoPlaying(true);
  };

  const handlePrizesClick = () => {
    setShowPrizeList(!showPrizeList);
    handleSetToPause();
    if (showPrizeList) {
      gaEventTrackerMyPrizes("click", "close");
    } else {
      gaEventTrackerVideo("click", "poppy prizes");
    }
  };

  const handlePlayPauseClick = () => {
    if (showStart) {
      gaEventTrackerHowToPlay("click", "play button");
      setShowStart(false);
      if (!showEnd) {
        handleSetToPlay();
      }
    } else {
      if (videoPlaying) {
        handleSetToPause();
        gaEventTrackerVideo("click", "pause");
      } else {
        handleSetToPlay();
        gaEventTrackerVideo("click", "play");
      }
    }
  };

  const handleStartEndButtonClick = () => {
    if (showStart) {
      gaEventTrackerHowToPlay("click", "play game");
      setShowStart(false);
      if (!showEnd) {
        handleSetToPlay();
      }
    } else {
      setShowStart(true);
      handleSetToPause();
      gaEventTrackerVideo("click", "how to play");
    }
  };

  const handleRestartVideo = () => {
    const player = document.getElementById("video");

    setProgressPercent(0);
    player.currentTime = 0;
    handleSetToPlay();
    lottieRef.current.goToAndPlay(0, true);
  };

  const handleEndSearchClick = () => {
    setShowEnd(false);
    handleRestartVideo();
    gaEventTrackerNiceTry("click", "keep looking");
  };

  const scrubStart = (e) => {
    if (!vidRef.current.paused) {
      handleSetToPause();
      returnToPlaying.current = true;
    }
    scrubbing.current = true;
    seek(e);
  };

  const scrubEnd = () => {
    scrubbing.current = false;
    if (returnToPlaying.current && !showEnd) {
      handleSetToPlay();
      returnToPlaying.current = false;
    }
  };

  const scrubMove = (e) => {
    if (scrubbing.current) {
      seek(e);
    }
  };

  const handleRemoveClickEvent = useCallback((currentId) => {
    document
      .getElementById(currentId)
      .removeEventListener("click", handleBoxClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBoxClick = useCallback(
    (e) => {
      const hasClicked19 =
        e.currentTarget.id === "TARGET19a" ||
        e.currentTarget.id === "TARGET19b";
      handleRemoveClickEvent(e.currentTarget.id);

      if (hasClicked19) {
        handleRemoveClickEvent("TARGET19a");
        handleRemoveClickEvent("TARGET19b");
      }

      if (!foundPoppys.includes(e.currentTarget.id)) {
        const lottieContainer =
          lottieRef.current?.animationContainerRef.current;

        handleExplodeParticles(e, lottieContainer);
        setPoppyFound(1);
        setTotalClicked((previous) => previous + 1);
        setFoundPoppys((previous) => [...previous, e.currentTarget.id]);
        hasClicked.current = true;
        gaEventTrackerVideo(
          "click",
          `poppy #${e.currentTarget.id.replace("TARGET", "")}`
        );

        // The click sound is played in the totalClicked UseEffect
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [foundPoppys, handleRemoveClickEvent, setFoundPoppys]
  );

  const size = useWindowSize();
  const isSmallScreen = size.width < 1020;
  const overlayScreen = isSmallScreen ? (
    <StartgameOverySmall
      setShowStart={setShowStart}
      handleStartEndButtonClick={handleStartEndButtonClick}
    />
  ) : (
    <StartgameOveryLarge
      setShowStart={setShowStart}
      handleStartEndButtonClick={handleStartEndButtonClick}
    />
  );

  useEffect(() => {
    setControlsWidth(controlsRef.current?.offsetWidth);
  }, []);

  useEffect(() => {
    setVideoHeight(vidRef.current?.offsetHeight);
  }, [videoHeight]);

  useEffect(() => {
    return () => {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement
      ) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    };
  }, []);

  useEffect(() => {
    if (totalClicked === totalNumberOfPoppysAvailable) {
      setShowEnd(true);
    }

    if (hasClicked.current) {
      totalClicked !== totalNumberOfPoppysAvailable
        ? playableFoundPoppySound.play()
        : playableFoundAllSound.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalClicked]);

  const seek = useCallback((e) => {
    const progressBar = document.getElementById("progress-bar");
    const player = document.getElementById("video");
    let offset = e.offsetX;
    if (e.targetTouches) {
      offset = e.targetTouches[0]?.pageX;
    }
    const viewportOffset = isMobile
      ? (window.innerWidth - progressBar.offsetWidth) / 2
      : 0;
    const percent = (offset - viewportOffset) / progressBar.offsetWidth;
    const roundedTime = percent < 0 ? 0 : percent * player.duration;
    const framerate = 30;
    const frameNumber = roundedTime * framerate;
    player.currentTime = roundedTime;
    if (vidRef.current.paused) {
      lottieRef.current.goToAndStop(frameNumber, true);
    } else {
      lottieRef.current.goToAndPlay(frameNumber, true);
    }
  }, []);

  const updateProgressBar = useCallback(() => {
    const player = document.getElementById("video");
    if (!player) {
      return;
    }
    const percentage = (100 / player.duration) * player.currentTime + 1;
    const framerate = 30;
    const frameNumber = player.currentTime * framerate;
    if (vidRef.current.paused) {
      lottieRef.current.goToAndStop(frameNumber, true);
    } else {
      lottieRef.current.goToAndPlay(frameNumber, true);
    }
    setProgressPercent(percentage);

    if (percentage > 0 && percentage < 2) {
      gaEventTrackerVideo("view", "start");
    }
    if (percentage >= 25 && percentage < 26) {
      gaEventTrackerVideo("view", "25 percent");
    }
    if (percentage >= 50 && percentage < 51) {
      gaEventTrackerVideo("view", "50 percent");
    }
    if (percentage >= 75 && percentage < 76) {
      gaEventTrackerVideo("view", "75 percent");
    }
    if (percentage >= 100) {
      gaEventTrackerVideo("view", "100 percent");
    }
  }, [gaEventTrackerVideo]);

  useEffect(() => {
    try {
      if (isMobile) {
        setProgressPercent(0);
        handleSetToPause();
        setShowStart(true);
        setShowEnd(false);
        setShowPrizeList(false);
        setProgressPercent(0);
        setVideoPlaying(false);
      }
    } catch (e) {}
    ids.forEach((elm) => {
      const el = document.getElementById(elm);
      if (!!el) {
        // This logic adds a group so we can add an explosion to the target when clicked
        const childGroup = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "g"
        );
        el.addEventListener("click", handleBoxClick);
        childGroup.setAttribute("class", "btn-particles");
        el.appendChild(childGroup);
      }
    });
    const progressBar = document?.getElementById("progress-bar");
    const player = document?.getElementById("video");

    player?.addEventListener("timeupdate", updateProgressBar, false);
    player?.addEventListener("ended", handleVideoEnded, false);

    // add event listeners to handle scrub
    progressBar?.addEventListener("mousedown", scrubStart, false);
    progressBar?.addEventListener("touchstart", scrubStart, false);
    window?.addEventListener("mouseup", scrubEnd, false);
    window?.addEventListener("touchend", scrubEnd, false);
    progressBar?.addEventListener("mousemove", scrubMove, false);
    progressBar?.addEventListener("touchmove", scrubMove, false);

    return () => {
      ids.forEach((elm) => {
        document.getElementById(elm);
        // ?.removeEventListener("click", handleBoxClick);
      });

      player?.removeEventListener("ended", handleVideoEnded, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientation]);

  if (orientation === "portrait" && isMobile) {
    return <RotateDevice orientation={orientation} />;
  }

  console.log({ showStart, showEnd, showPrizeList });

  return (
    <StyledVideoPage
      className={classNames(`video-page__${orientation}`, {
        "video-page--wide": controlsWidth > document.body.clientWidth,
      })}
      $height={videoHeight}
    >
      <div className="video-page__wrapper">
        <div className="video-page__controls-wrapper" ref={controlsRef}>
          <div className="video-page__outer-totals-wrapper">
            <div className="video-page__totals-wrapper">
              <Link
                to="/"
                className="video-page__logo-wrapper"
                onClick={() =>
                  showStart
                    ? gaEventTrackerHowToPlay("click", "Home Button")
                    : gaEventTrackerVideo("click", "Home Button")
                }
              >
                <img
                  src={logo}
                  alt="Popeyes Poppy the chicken logo"
                  className="video-page__logo"
                />
              </Link>

              <span className="video-page__totals">
                {totalClicked}/{totalNumberOfPoppysAvailable}
                <span
                  className="video-page__plus-one"
                  onAnimationEnd={() => setPoppyFound(0)}
                  clicked={poppyFound}
                >
                  +1
                </span>
              </span>
            </div>
            {showStart && !isSmallScreen && <UpperLeftLarge />}
          </div>
          <div className="video-page__control__prizes-wrapper">
            {showStart && !isSmallScreen && <UpperRightLarge />}
            <button
              onClick={handlePrizesClick}
              className="video-page__control__prizes"
              disabled={showStart}
            >
              <img
                src={enterNowRibbon}
                alt="My Poppy Prizes"
                className="video-page__control__prizes-image"
              />
            </button>
          </div>
          <div
            className={classNames("video-page__control__play-button-wrapper", {
              "video-page__control__play-button-wrapper--ios-with-home-button":
                isIOS && isMobile,
            })}
          >
            {showStart && !isSmallScreen && <LowerLeftLarge />}
            <button
              className="video-page__control__button"
              onClick={handlePlayPauseClick}
            >
              {videoPlaying ? (
                <img
                  src={pauseIcon}
                  alt="Pause button"
                  className="video-page__control-play-pause pause"
                />
              ) : (
                <img
                  src={playIcon}
                  alt="Play button"
                  className="video-page__control-play-pause"
                />
              )}
            </button>
          </div>
          {showStart && !isSmallScreen && <LowerRightLarge />}
          {!showStart && (
            <CtaButton
              className={classNames("video-page__control__end", {
                "video-page__control__end--ios-with-home-button":
                  isIOS && isMobile,
              })}
              ctaText={startEndButtonText}
              ctaColor="orangeLight"
              onClick={handleStartEndButtonClick}
            />
          )}
        </div>

        <video
          ref={vidRef}
          id="video"
          webkit-playsinline="true"
          playsInline
          style={{ pointerEvents: `${showEnd ? "none" : ""}` }}
        >
          <source src={backgroundMovie} type="video/mp4" />
        </video>

        <div
          className={classNames("video-page__progress-wrapper", {
            "video-page__progress-wrapper--ios-with-home-button":
              isIOS && isMobile,
          })}
        >
          <InputRange
            classNames={{
              inputRange: "input-range",
              disabledInputRange: "input-range--disabled",
              minLabel: "input-range__min-label",
              maxLabel: "input-range__max-label",
              valueLabel: "input-range__value-label",
            }}
            maxValue={100}
            minValue={0}
            value={progressPercent}
            disabled={showStart}
            onChange={() => setProgressPercent(progressPercent)}
          />

          <input
            className={classNames("video-page__progress", {
              "video-page__progress--mobile": isMobile,
            })}
            type="range"
            id="progress-bar"
            min="0"
            max="100"
            value={progressPercent}
            disabled={showStart || showEnd}
            aria-hidden="true"
          />
        </div>
        <div className="video-page__animation-wrapper">
          <Lottie
            animationData={animationsData}
            autoplay={false}
            loop={false}
            lottieRef={lottieRef}
          />
        </div>
        {showStart && overlayScreen}
        {showEnd && !showStart && (
          <EndGameOverlay
            handleEndSearchClick={handleEndSearchClick}
            handlePrizesClick={handlePrizesClick}
            totalClicked={totalClicked}
          />
        )}
        {showPrizeList && !showStart && (
          <PrizesOverlay
            handleClick={handlePrizesClick}
            totalClicked={totalClicked}
          />
        )}
      </div>
    </StyledVideoPage>
  );
};

const StyledVideoPage = styled.section`
  background: #000;

  &.video-page {
    &__landscape {
      aspect-ratio: 16 / 9;
      height: 100dvh;
      margin: 0 auto;
      overflow: hidden;
    }

    &__portrait {
      display: flex;
      height: 100dvh;
      max-width: 100%;
      overflow: hidden;
      position: relative;
    }
  }

  &.video-page--wide {
    height: auto;
    width: 100%;

    .video-page__controls-wrapper {
      height: auto;
    }
  }

  video {
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    max-height: 100%;
  }

  .video-page {
    &__wrapper {
      display: grid;
      height: 100%;
      position: relative;
      width: 100%;
    }

    &__inner-wrapper {
      align-self: center;
      height: calc(100vw * 9 / 16);
      position: relative;
      width: 100%;
    }

    &__controls-wrapper {
      align-items: start;
      aspect-ratio: 16 / 9;
      display: grid;
      grid-area: 1 / 1;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      height: 100dvh;
      justify-items: start;
      width: 100%;
    }

    &__outer-totals-wrapper {
      display: flex;
      position: relative;
      z-index: 3;
    }

    &__totals-wrapper {
      align-items: center;
      display: flex;
      padding-left: 1.5rem;
      padding-top: 1.5rem;

      @media (min-width: 1020px) {
        flex-direction: column;
        justify-content: center;
      }
    }

    &__totals {
      position: relative;
      align-items: center;
      background-color: ${({ theme }) => theme.colors.orangeLight};
      border-radius: 50px;
      color: ${({ theme }) => theme.colors.offWhite};
      display: flex;
      font-size: 10px;
      height: 20.3px;
      justify-content: center;
      margin-left: 10px;
      width: 60px;

      @media (min-width: 1020px) {
        height: 35px;
        font-size: 20px;
        margin-left: 0;
        margin-top: 0.75rem;
        width: 90px;
      }
    }

    &__plus-one {
      display: none;
      color: white;
      position: absolute;
      left: 20px;
    }
    &__plus-one[clicked="1"] {
      display: block;
      animation: fade-in-up 1s ease;
    }
    @keyframes fade-in-up {
      0% {
        display: none;
      }
      1% {
        display: block;
      }
      10% {
        top: 0%;
        opacity: 1;
      }
      100% {
        top: -200%;
        opacity: 0;
        display: none;
      }
    }

    &__logo-wrapper {
      align-items: center;
      background-color: ${({ theme }) => theme.colors.orangeLight};
      border-radius: 50%;
      display: flex;
      height: 2.5rem;
      justify-content: center;
      width: 2.5rem;

      @media (min-width: 1020px) {
        height: 4.5rem;
        width: 4.5rem;
      }
    }

    &__logo {
      display: block;
      margin-right: 5px;
      width: 60%;

      @media (min-width: 960px) {
        margin-right: 8px;
      }
    }

    &__animation-wrapper {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      overflow: hidden;
      div {
        width: 100%;
      }
    }
  }

  .video-page__control {
    &__prizes-wrapper {
      align-self: start;
      display: flex;
      justify-self: end;
      padding-right: 1.5rem;
      padding-top: 1.5rem;
      position: relative;
      z-index: 3;
    }

    &__prizes {
      cursor: pointer;
      width: 64px;

      @media (min-width: 1020px) {
        width: 116px;
      }
    }

    &__play-button-wrapper {
      align-self: end;
      display: flex;
      margin-bottom: 12%;
      margin-left: 1.5rem;
      position: relative;
      z-index: 3;

      @media (min-width: 1200px) {
        margin-bottom: 9%;
      }

      &--ios-with-home-button {
        margin-bottom: 22%;

        @media (min-width: 780px) {
          margin-bottom: 17%;
        }

        @media (min-width: 960px) {
          margin-bottom: 12%;
        }
      }
    }

    &__button {
      align-items: center;
      aspect-ratio: 1;
      background-color: ${({ theme }) => theme.colors.orangeLight};
      border: 0;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 2.5rem;
      justify-content: center;
      outline: none;
      padding: 0.75rem;
      width: 2.5rem;

      @media (min-width: 1020px) {
        height: 3.5rem;
        width: 3.5rem;
      }
    }

    &-play-pause {
      transform: translateX(1px);

      @media (min-width: 960px) {
        margin-right: 3px;
      }

      @media (min-width: 1020px) {
        transform: translateX(2px);
      }

      &.pause {
        @media (max-width: 1019px) {
          transform: scale(0.8);
        }
        <br / > @media (min-width: 960px) {
          transform: translateX(1px);
        }
      }
    }

    &__end {
      align-self: end;
      cursor: pointer;
      font-size: 1rem;
      justify-self: end;
      margin-bottom: 12%;
      margin-right: 1rem;
      z-index: 3;
      &--ios-with-home-button {
        margin-bottom: 22%;
      }

      @media (min-width: 1020px) {
        font-size: 1.5rem;
      }

      @media (min-width: 1200px) {
        margin-bottom: 6%;
      }
    }

    &__prizes-image {
      display: block;
      margin: 0 auto;
      max-width: 44px;
      width: 100%;

      @media (min-width: 1020px) {
        max-width: 80px;
      }
    }
  }

  .video-page__progress-wrapper {
    bottom: 2%;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 98%;
    z-index: 3;
    &--ios-with-home-button {
      bottom: 1.75rem;
    }
  }

  .video-page__progress {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;

    &[disabled] {
      pointer-events: none;
    }

    &::-webkit-slider-runnable-track {
      background: rgb(255, 245, 225);
      background: linear-gradient(
        90deg,
        rgba(255, 245, 225, 1) 29%,
        rgba(0, 178, 169, 1) 100%
      );
      border-radius: 5px;
      height: 10px;
    }

    &--mobile {
      height: 12px !important;
      &::-moz-range-track {
        height: 6px !important;
        margin-bottom: 7px !important;
      }
      &::-webkit-slider-runnable-track {
        height: 6px !important;
        margin-bottom: 7px !important;
        background-color: red;
      }
      &::-webkit-slider-thumb {
        width: 1.5rem !important;
        height: 1.5rem !important;
        margin-top: -9px !important;
        background-color: red;
      }
      &::-moz-range-thumb {
        width: 1.5rem !important;
        height: 1.5rem !important;
        margin-top: -9px !important;
      }
    }

    &::-moz-range-track {
      background: rgb(255, 245, 225);
      background: linear-gradient(
        90deg,
        rgba(255, 245, 225, 1) 29%,
        rgba(0, 178, 169, 1) 100%
      );
      border-radius: 5px;
      height: 10px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      background-color: ${({ theme }) => theme.colors.offWhite};
      border-radius: 1rem;
      height: 2rem;
      margin-top: -12px;
      width: 2rem;
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      background-color: ${({ theme }) => theme.colors.offWhite};
      border: none;
      border-radius: 1rem;
      height: 2rem;
      margin-top: -12px;
      width: 2rem;
    }
  }
`;

export default VideoSection;
