import React from "react";
import styled from "styled-components";

import portraitToLandscape from "../../assets/images/portrait-to-landscape.png";
import landscapeToPortrait from "../../assets/images/landscape-to-portrait.png";

const RotateDevice = ({ orientation }) => {
  return (
    <StyledOverlay>
      <img
        src={
          orientation === "landscape"
            ? landscapeToPortrait
            : portraitToLandscape
        }
        alt="screen rotating"
        className="orientation-overlay__image"
      />
      <p className="orientation-overlay__text">Please rotate your screen</p>
    </StyledOverlay>
  );
};

const StyledOverlay = styled.div`
  align-items: center;
  background: #005560;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 700;

  .orientation-overlay {
    &__image {
      width: 35%;
      max-width: 440px;

      @media (min-width: 960px) {
        width: 65%;
      }
    }

    &__text {
      color: ${({ theme }) => theme.colors.offWhite};
      font-size: 25px;
      margin-top: 50px;

      @media (min-width: 1020px) {
        font-size: 30px;
      }
    }
  }
`;

export default RotateDevice;
