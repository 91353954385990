import React from "react";
import styled from "styled-components";

import facebookIcon from "../../assets/images/fb.png";
import instaIcon from "../../assets/images/insta.png";
import twitterIcon from "../../assets/images/twitter.png";
import tiktokIcon from "../../assets/images/tiktok.png";

import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const SocialLinks = ({ collectedAll, myPrizes }) => {
  let trackerType;
  if (myPrizes) {
    trackerType = "myPrizes";
  } else if (collectedAll) {
    trackerType = "endscreen_whoowee";
  } else if (!collectedAll) {
    trackerType = "endscreen_nicetry";
  }
  const gaEventTracker = useAnalyticsEventTracker(trackerType);

  return (
    <StyledSocialLinks id="social-links">
      <li className="social-links__item">
        <a
          name="fb_share"
          type="button"
          href="https://www.facebook.com/sharer.php?u=www.findpoppy.com&t=Test"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => gaEventTracker("click", "Facebook")}
        >
          <img src={facebookIcon} alt="Facebook" />
        </a>
      </li>
      <li className="social-links__item">
        <a
          href="https://www.instagram.com/popeyes/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => gaEventTracker("click", "Instagram")}
        >
          <img src={instaIcon} alt="Instagram" />
        </a>
      </li>
      <li className="social-links__item">
        <a
          href="http://twitter.com/share?text=NOLA. For the love of chicken, who wants to go with me?&url=https://www.findpoppy.com"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => gaEventTracker("click", "Twitter")}
        >
          <img src={twitterIcon} alt="Twitter" />
        </a>
      </li>
      <li className="social-links__item">
        <a
          href="https://www.tiktok.com/@popeyes"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => gaEventTracker("click", "Tik Tok")}
        >
          <img src={tiktokIcon} alt="Tik Tok" />
        </a>
      </li>
    </StyledSocialLinks>
  );
};

const StyledSocialLinks = styled.ul`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: scale 0.2s ease-out;

  a,
  button {
    cursor: pointer;

    img {
      &:focus,
      &:hover {
        scale: 1.1;
      }
    }
  }
`;

export default SocialLinks;
