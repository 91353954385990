import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import homeIcon from "../../assets/images/home-icon.svg";

import { content } from "../../content";

const PrivacyPolicyPage = () => {
  const handleBackToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Edge, and Firefox
  };

  return (
    <StyledPrivacyPolicyPage>
      <div className="page__wrapper">
        <Link className="page__home-link" to="/">
          <img src={homeIcon} alt="Poppy the chicken in a house" />
        </Link>
        <div className="page__content">
          <div className="page__heading-wrapper">
            <h1>{content.privacyPolicyPage.heading}</h1>
            <article
              dangerouslySetInnerHTML={{
                __html: content.privacyPolicyPage.copy,
              }}
            />
          </div>
        </div>
        <button
          className="back-to-top-btn"
          type="button"
          onClick={handleBackToTop}
        >
          Back to top
        </button>
      </div>
    </StyledPrivacyPolicyPage>
  );
};

const StyledPrivacyPolicyPage = styled.section`
  background-color: ${({ theme }) => theme.colors.offWhite};

  .page {
    &__home-link {
      left: 0.75rem;
      position: absolute;
      top: 0.75rem;
      width: 2rem;
      z-index: 2;

      @media (min-width: 1080px) {
        width: auto;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      max-width: 56rem;
      padding: 1.5rem;
    }

    &__heading-wrapper {
      h1 {
        font-family: "ChickenSans-Bold", sans-serif;
        font-size: 2.25rem;
        line-height: 1;
        margin-bottom: 1.5rem;
        text-align: center;

        @media (min-width: 1080px) {
          font-size: 4rem;
        }
      }

      a {
        white-space: pre-wrap; /* CSS3 */
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-wrap: break-word; /* Internet Explorer 5.5+ */
      }

      p,
      li {
        font-family: "ChickenSans-Regular", sans-serif;
        font-size: 1.125rem;
        line-height: 1.2;
        margin-bottom: 1rem;
      }
    }
  }

  .back-to-top-btn {
    cursor: pointer;
    font-size: 0.875rem;
    margin-top: 4.5rem;
  }
`;

export default PrivacyPolicyPage;
